import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Card from "@civicplus/preamble-ui/lib/Card";
import PreambleLoader from "@civicplus/preamble-ui/lib/Loader";
import Stepper from "@civicplus/preamble-ui/lib/Stepper";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { LinearProgress } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import { useCallback, useEffect, useState } from "react";
import Timer from "react-compound-timer";
import { siteSetupHub } from "../../api/hubs";
import { millisecondsToSeconds } from "../../utilities/timeUtilities";

export const OperationProgress = ({ messageCreating, onComplete, name, error, id, hubName }) => {
	const [steps, setSteps] = useState([]);
	const [isCompleted, setIsComplete] = useState(false);
	const [elapsedSetupTime, setElapsedSetupTime] = useState(0);
	const [newId, setNewId] = useState("");

	const onCompletedClick = useCallback(() => {
		onComplete && onComplete(newId);
	}, [onComplete, newId]);

	useEffect(() => {
		siteSetupHub.off(hubName);
		siteSetupHub.on(hubName, progress => {
			const operations = progress.operations;
			if (id !== progress.id && id !== progress.name) {
				return; // we are only interested in progress for the operation we are on.
			}

			const makeIcon = props => {
				const { completed, error, icon } = props;
				if (error) {
					return <WarningIcon style={{ fill: red.A700 }} />;
				} else if (completed) {
					return <CheckCircleIcon style={{ fill: green[800] }} />;
				}
				return (
					<div>
						<PreambleLoader title={icon} size={20} />
					</div>
				);
			};

			setElapsedSetupTime(progress.elapsedTimeInMilliseconds);
			setIsComplete(progress.isCompleted);
			setNewId(progress.id);

			setSteps(
				operations.map(o => {
					const stepOptions = {
						label: (
							<Tooltip
								interactive
								arrow
								TransitionComponent={Fade}
								title={millisecondsToSeconds(o.timeTakenInMilliseconds) + " seconds"}
							>
								<span>{o.name}</span>
							</Tooltip>
						),
						completed: o.isCompleted,
						StepIconComponent: makeIcon
					};

					if (o.isErrored) {
						stepOptions.label = (
							<Tooltip interactive arrow TransitionComponent={Fade} title={o.errorMessage}>
								<Typography variant="caption" color="error">
									{o.name} had an error
								</Typography>
							</Tooltip>
						);
						stepOptions.error = true;
					}
					return stepOptions;
				})
			);
		});
	}, [id, hubName]);
	return (
		<Card
			title={
				<Typography variant="h5" gutterBottom>
					{isCompleted
						? `Completed in ${millisecondsToSeconds(elapsedSetupTime)} seconds.`
						: error
						? `Error creating ${name}`
						: messageCreating}
				</Typography>
			}
			subheader={isCompleted || error ? null : <LinearProgress />}
			content={
				<>
					{error && (
						<>
							<Typography variant="caption" color="error">
								<WarningIcon style={{ verticalAlign: "middle", margin: "5px" }} />
								Error {error}
							</Typography>
						</>
					)}
					<Stepper steps={steps} orientation="vertical" />
				</>
			}
			footer={
				<div>
					{(isCompleted || error) && onComplete ? (
						<ButtonGroup layout="right">
							<Button color="primary" onClick={onCompletedClick}>
								Continue
							</Button>
						</ButtonGroup>
					) : (
						<div>
							<span>Operations in progress...</span> (
							<Timer>
								<Timer.Minutes /> minutes &nbsp;
								<Timer.Seconds /> seconds
							</Timer>{" "}
							)
						</div>
					)}
				</div>
			}
		/>
	);
};
