import Breadcrumbs from "@civicplus/preamble-ui/lib/Breadcrumbs/Breadcrumbs";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import DropDown from "@civicplus/preamble-ui/lib/DropDown";
import Grid from "@civicplus/preamble-ui/lib/Grid/Grid";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import SnackBarContent from "@civicplus/preamble-ui/lib/SnackBarContent";
import TextInput from "@civicplus/preamble-ui/lib/TextInput/TextInput";
import enhanceWithValidation, { requiredValidation } from "@civicplus/preamble-ui/lib/Validations";
import { createClusterAsync } from "api/clustersApi";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { Region, getRegionFromValue, regionDropDownOptions } from "./Types/Region";

const TextInputWithValidation = enhanceWithValidation(TextInput);
const DropDownWithValidation = enhanceWithValidation(DropDown);

interface ClusterManagementProps {
	clusterId: string;
}

export interface ClusterModifications {
	name: string | undefined;
	description: string | undefined;
	region: Region | undefined;
	ipAddress: string | undefined;
}

export const ClusterCreateAndModify: React.FC<ClusterManagementProps> = ({ clusterId }) => {
	const [clusterModifications, setClusterModifications] = useState<ClusterModifications>({
		name: undefined,
		description: undefined,
		region: Region["Central US"],
		ipAddress: undefined
	});
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const nameRef = useRef<{ validate: () => { error: boolean } }>();
	const descriptionRef = useRef<{ validate: () => { error: boolean } }>();
	const regionRef = useRef<{ validate: () => { error: boolean } }>();
	const validationRefs = [nameRef, descriptionRef, regionRef];
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const saveAsync = useCallback(async () => {
		setIsSaving(true);

		try {
			history.push(`/engage6/operations/progress/createCluster/${clusterModifications.name}`);
			await createClusterAsync(clusterModifications);
		} catch (ex: any) {
			console.error(ex);
			if (ex.message !== "Network Error") {
				// Ignore request timeout error
				const defaultMessage = "Something went wrong when saving the cluster.";
				enqueueSnackbar(getErrorMessageFromResponse(ex, ex.message || defaultMessage), {
					variant: "error"
				});
			}
		}

		setIsSaving(false);
	}, [enqueueSnackbar, clusterModifications, history]);

	const clusterHasChanges = useMemo((): boolean => {
		// We need to check if region changed from central since it is the default.
		// If we add a modify screen we will need to add an additonal check here to see if it changed from the cluster value.
		if (clusterModifications.region !== Region["Central US"]) {
			return true;
		}

		return Object.keys(clusterModifications)
			.filter((key: string) => key !== "region")
			.some((key: string) => clusterModifications[key as keyof ClusterModifications] !== undefined);
	}, [clusterModifications]);

	const checkValidation = async () => {
		let isValid = true;
		const validationResultsPromise = validationRefs.map(async ref => {
			if (ref.current) {
				const result = await ref.current?.validate();
				if (result.error) {
					isValid = false;
				}
			}
		});

		await Promise.all(validationResultsPromise);
		return isValid;
	};

	return (
		<>
			<Breadcrumbs id="manageSiteForm-breadcrumbs">
				<Link to="/engage6/resources/clusters">Clusters</Link>
				<div>{clusterId || "Add Cluster"}</div>
			</Breadcrumbs>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<ButtonGroup layout="right">
						<Button
							id="cluster-save"
							disabled={!clusterHasChanges || isSaving}
							size="small"
							color="primary"
							onClick={async () => {
								if (await checkValidation()) {
									saveAsync();
								}
							}}
						>
							{isSaving ? (
								<span>
									Saving <Loader thickness={8} size={10} />
								</span>
							) : (
								"Save"
							)}
						</Button>
					</ButtonGroup>
				</Grid>
				<Grid item xs={12} />
			</Grid>
			<Grid container spacing={2}>
				{isSaving && (
					<Grid item xs={12}>
						<SnackBarContent
							id="several-minutes-info"
							message="This operation could take several minutes to complete. Please do not navigate away from this page."
							variant="info"
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<TextInputWithValidation
						ref={nameRef}
						validations={[requiredValidation]}
						id="clusterName"
						variant={"filled"}
						fullWidth={true}
						label="Cluster Name"
						value={clusterModifications.name}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							// If they empty out the field, we need to set it back to undefined for the clusterHasChanges check.
							const value = e.target.value !== "" ? e.target.value : undefined;
							setClusterModifications(prevState => ({
								...prevState,
								name: value
							}));
						}}
						inputProps={{ "data-testid": "clusterName" }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInputWithValidation
						ref={descriptionRef}
						validations={[requiredValidation]}
						id="clusterDescription"
						variant={"filled"}
						fullWidth={true}
						label="Cluster Description"
						value={clusterModifications.description}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							// If they empty out the field, we need to set it back to undefined for the clusterHasChanges check.
							const value = e.target.value !== "" ? e.target.value : undefined;
							setClusterModifications(prevState => ({
								...prevState,
								description: value
							}));
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<DropDownWithValidation
						ref={regionRef}
						validations={[requiredValidation]}
						id="clusterRegion"
						variant={"filled"}
						fullWidth={true}
						label="Region"
						options={regionDropDownOptions}
						value={regionDropDownOptions.find(x => {
							const value = clusterModifications.region;
							return x.value === value;
						})}
						onChange={(dropdownValue: { label: string; value: string }) => {
							// If we change it to the same value, set back to undefined to indicate nothing changed.
							const value = dropdownValue.value;
							setClusterModifications(prevState => ({
								...prevState,
								region: getRegionFromValue(value)
							}));
						}}
					/>
				</Grid>
			</Grid>
		</>
	);
};
