import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import List from "@civicplus/preamble-ui/lib/List";
import memoize from "memoize-one";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { deleteDatabaseAsync, listDatabasesAsync } from "../../../api/database-management";
import Loader from "../../loader/Loader";

const columns = [
	{
		name: "Database Server",
		label: "Database Server",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "Database Name",
		label: "Database Name",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "Tags",
		label: "Tags",
		options: {
			filter: true,
			sort: true
		}
	}
];

const databasesForAdvancedTable = memoize(databases => {
	return databases.map(database => {
		const databaseServer = database.server;
		const databaseName = database.name;
		return [
			databaseServer,
			databaseName,
			Object.keys(database.tags)
				.map(key => `${key}: ${database.tags[key]}`)
				.join(", ")
		];
	});
});

export const DatabaseManager = () => {
	const [orphanDatabases, setOrphanDatabases] = useState([]);
	const [databasesToRemove, setDatabasesToRemove] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleting, setIsDeleting] = useState(false);
	useEffect(() => {
		async function fetchData() {
			try {
				const result = await listDatabasesAsync();
				setOrphanDatabases(result);
				setIsLoading(false);
			} catch (ex) {
				if (ex && ex.response && ex.response.status === 403) {
					window.location.assign("/403");
					return;
				}
			}
		}
		fetchData();
	}, [setOrphanDatabases]); // Or [] if effect doesn't need props or state

	const options = {
		pagination: false,
		responsive: "vertical",
		onRowsDelete: rowsDeleted => {
			const databasesToRemove = orphanDatabases.filter((x, index) => rowsDeleted.data.find(y => y.dataIndex === index));
			setDatabasesToRemove(databasesToRemove);
		}
	};

	const clearDeleted = () => {
		setDatabasesToRemove([]);
	};

	const deleteDatabases = () => {
		try {
			setIsDeleting(true);
			const dbs = databasesToRemove.map(db => ({ name: db.name, server: db.server }));

			const removalTasks = dbs.map(async db => {
				await deleteDatabaseAsync(db);
				setDatabasesToRemove(databasesToRemove.filter(d => d.server != db.server && d.name != db.name));
			});

			Promise.all(removalTasks).then(() => {
				setIsDeleting(false);
			});
		} catch (ex) {
			console.error(ex);
			if (ex.message !== "Network Error") {
				// Ignore request timeout error
				const defaultMessage = "Something went wrong when running the request.";
				enqueueSnackbar(getErrorMessageFromResponse(ex, ex.message || defaultMessage), {
					variant: "error"
				});
			}
		}
	};

	return (
		<>
			<Dialog
				actions={[
					<Button key="ok" disabled={isDeleting} onClick={deleteDatabases}>
						Proceed
					</Button>,
					<Button key="cancel" disabled={isDeleting} onClick={clearDeleted}>
						Cancel
					</Button>
				]}
				onClose={clearDeleted}
				open={databasesToRemove.length}
				fullWidth
				maxWidth="sm"
				title="IRREVERSIBLE  OPERATION: Deleting Databases."
			>
				<>
					<div>
						You are about to REMOVE the following databases. This operation cannot be reversed. Please confirm .
					</div>
					<List id="databaes-to-remove" listItems={databasesToRemove.map(d => ({ itemText: { primary: d.name } }))} />
				</>
			</Dialog>
			<div>
				<Link to="/engage6/resources/databases/querydbs">
					<Button id="query-dbs">Query DBs</Button>
				</Link>
			</div>
			{isLoading ? (
				<Loader>Loading...</Loader>
			) : (
				<AdvancedTable
					id="database-list"
					title="Databases"
					columns={columns}
					data={databasesForAdvancedTable(orphanDatabases)}
					options={options}
				/>
			)}
		</>
	);
};
