import { executeRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";
import userManager from "./userManager";

const getUserTokenAsync = async () => {
	const user = await userManager.getUserAsync();
	if (user) {
		return user.access_token;
	}
};

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/hcms/apps/?hcmsToken={{hcmsToken}}&env={{env}}&orgId={{orgId}}`
	},
	getToken: {
		method: "POST",
		path: `${getApiUrl()}/api/hcms/token/?userToken={{userToken}}&env={{env}}`
	},
	getEnv: {
		method: "GET",
		path: `${getApiUrl()}/api/hcms/environments`
	},
	getOrgs: {
		method: "GET",
		path: `${getApiUrl()}/api/organizations`
	},
	createApp: {
		method: "POST",
		path: `${getApiUrl()}/api/hcms/app`
	},
	modifyApp: {
		method: "PUT",
		path: `${getApiUrl()}/api/hcms/app`
	},
	modifyOrgServiceApp: {
		method: "PUT",
		path: `${getApiUrl()}/api/applications/?oldOrgId={{oldOrgId}}&newOrgId={{newOrgId}}&appName={{appName}}&env={{env}}`
	},
	createOrgServiceApp: {
		method: "POST",
		path: `${getApiUrl()}/api/applications`
	},
	deleteApp: {
		method: "DELETE",
		path: `${getApiUrl()}/api/hcms/app/?hcmsToken={{hcmsToken}}&env={{env}}&appName={{appName}}`
	},
	deleteOrgServiceApp: {
		method: "DELETE",
		path: `${getApiUrl()}/api/applications/?orgId={{orgId}}&appName={{appName}}&env={{env}}`
	}
};

const fetchHcmsAppsAsync = async (env, orgId = "") => {
	const hcmsToken = (await fetchHcmsTokenAsync(env)).data;
	const apps = (await executeRequestAsyncFor(endpoints.list, { env, hcmsToken, orgId })).data;
	return apps;
};

const fetchHcmsTokenAsync = async env => {
	const userToken = await getUserTokenAsync();
	return await executeRequestAsyncFor(endpoints.getToken, { userToken, env });
};

const fetchHcmsEnvironments = async () => {
	return await executeRequestAsyncFor(endpoints.getEnv);
};

const fetchAllOrganizations = async () => {
	const orgs = await executeRequestAsyncFor(endpoints.getOrgs);
	return orgs;
};

const createHcmsApp = async createAppRequest => {
	return await executeRequestAsyncFor(endpoints.createApp, createAppRequest);
};

const createOrgServiceApp = async createOrgServiceAppRequest => {
	return await executeRequestAsyncFor(endpoints.createOrgServiceApp, createOrgServiceAppRequest);
};

const modifyHcmsApp = async modifyAppRequest => {
	return await executeRequestAsyncFor(endpoints.modifyApp, modifyAppRequest);
};

const modifyOrgServiceApp = async (oldOrgId, newOrgId, appName, env) => {
	return await executeRequestAsyncFor(endpoints.modifyOrgServiceApp, { oldOrgId, newOrgId, appName, env });
};

const deleteHcmsApp = async (hcmsToken, env, appName) => {
	return await executeRequestAsyncFor(endpoints.deleteApp, { hcmsToken, env, appName });
};

const deleteOrgServiceApp = async (orgId, appName, env) => {
	return await executeRequestAsyncFor(endpoints.deleteOrgServiceApp, { orgId, appName, env });
};

export {
	createHcmsApp,
	createOrgServiceApp,
	deleteHcmsApp,
	deleteOrgServiceApp,
	fetchHcmsAppsAsync,
	fetchHcmsTokenAsync,
	fetchHcmsEnvironments,
	fetchAllOrganizations,
	getUserTokenAsync,
	modifyHcmsApp,
	modifyOrgServiceApp
};
