import { useEffect } from "react";
import userManager from "../../api/userManager";
import Loader from "../loader/Loader";

export const SignOut = () => {
	useEffect(() => {
		async function checkAuth() {
			const isAuthenticated = await userManager.isAuthenticatedAsync();

			// Only redirect if log out is from current product. Otherwise, the platform needs to call userManager.signOut() and doesn't need the redirect.
			if (isAuthenticated) {
				userManager.signOut();
			} else {
				window.location.assign(`/`);
			}
		}

		checkAuth();
	});

	return <Loader>Loading...</Loader>;
};
