import axios from "axios";
import Cookies from "js-cookie";
import userManager from "./userManager";

const interpolateStringWithObject = (val, interpolationDictionary) => {
	for (let item in interpolationDictionary) {
		if (interpolationDictionary.hasOwnProperty(item)) {
			val = val.replace(`{{${item}}}`, interpolationDictionary[item]);
		}
	}
	return val;
};
export const executeRequestAsyncFor = async (endpoint, data, id) => {
	const dataObjectForInterpolation = { ...data };
	if (id) {
		dataObjectForInterpolation.id = id;
	}
	const pathAfterInterpolation = interpolateStringWithObject(endpoint.path, dataObjectForInterpolation);

	const headers = await getDefaultHeadersAsync();
	if (["get", "delete"].includes(endpoint.method.toLowerCase())) {
		const config = { ...data, headers, withCredentials: true, credentials: "include" };
		return axios[endpoint.method.toLowerCase()](pathAfterInterpolation, config);
	} else {
		const config = { headers };
		return axios[endpoint.method.toLowerCase()](pathAfterInterpolation, data, config);
	}
};

export const executeFormRequestAsyncFor = async (endpoint, data, formData) => {
	const pathAfterInterpolation = interpolateStringWithObject(endpoint.path, data);
	const headers = await getDefaultHeadersAsync();
	const config = { headers: { "Content-Type": "multipart/form-data", ...headers } };
	return axios[endpoint.method.toLowerCase()](pathAfterInterpolation, formData, config);
};

const getDefaultHeadersAsync = async () => {
	const headers = {};

	// We need to add a cookie that we can look for when checking hangfire authentication since our ids authentication is
	// on a different domain than realms-api.
	const hostname = window.location.hostname;
	const finalHostname = hostname.substring(hostname.indexOf("."));
	headers["Access-Control-Allow-Origin"] = "*";
	headers["Access-Control-Allow-Credentials"] = true;

	const user = await userManager.getUserAsync();
	if (user) {
		Cookies.set("token", user.access_token, { domain: finalHostname, sameSite: "none", secure: true });
		headers.Authorization = `${user.token_type} ${user.access_token}`;
	}

	return headers;
};
