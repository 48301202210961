export enum Region {
	"Central US" = "centralus",
	"East US" = "eastus",
	"West US" = "westus"
}

// string enums can't reverse map, so we'll create a function that does it.
export const getRegionFromValue = (value: string): Region => {
	if (value === Region["Central US"]) {
		return Region["Central US"];
	}

	if (value === Region["West US"]) {
		return Region["West US"];
	}

	if (value === Region["East US"]) {
		return Region["East US"];
	}

	throw new Error("The region you used does not exist.");
};

export const getRegionValueForApi = (region: Region): string => {
	if (region === Region["Central US"]) {
		return "centralus";
	}

	if (region === Region["West US"]) {
		return "westus";
	}

	if (region === Region["East US"]) {
		return "eastus";
	}

	throw new Error("The region you used does not exist.");
};

export const getRegionKeyFromValue = (region: Region): string => {
	if (region === Region["Central US"]) {
		return "Central US";
	}

	if (region === Region["West US"]) {
		return "West US";
	}

	if (region === Region["East US"]) {
		return "East US";
	}

	throw new Error("The region you used does not exist.");
};

export const regionDropDownOptions = Object.values(Region).reduce(
	(final: { label: string; value: string }[], current) => {
		final.push({ label: getRegionKeyFromValue(current), value: current });

		return final;
	},
	[]
);
