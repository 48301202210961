export interface AppDetails {
	name: string;
	org: string;
	hmcsEnvironment: string;
	hcmsToken: string;
}

export interface OrgServiceAppDetails {
	id: string;
	name: string;
	organizationId: string;
	productType: number;
	productUri: string;
}

export const ProductUrls = {
	qa: "https://content-qa.civicplus.com",
	review: "https://content-review.civicplus.com",
	staging: "https://content-staging.civicplus.com",
	production: "https://content.civicplus.com",
	sandbox: "https://content-sandbox.civicplus.com"
};
