/**
 * These window values can be found in /src/public/env.js for local development
 *
 * The production/staging values live in their appropriate appsettings files
 *
 * Any changes to this file should be replicated in the development appsettings files
 */

export const getApiUrl = () => (shouldUseLocalDevelopmentApiUrl() ? getLocalDevelopmentApiUrl() : window.env.API_URL);

const getLocalDevelopmentApiUrl = () => window.env.LOCAL_API_URL;

const shouldUseLocalDevelopmentApiUrl = () => window.env.USE_LOCAL_API;

export const getDatabaseServerName = () => window.env.DATABASE_SERVER;

export const getDefaultDomain = () => window.env.DEFAULT_DOMAIN;

export const getIsAddEnvEnabled = () => window.env.ADD_ENV_ENABLED;

export const isDefaultDomain = domainName => domainName && domainName.indexOf(getDefaultDomain()) > -1;
