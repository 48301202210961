import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import memoize from "memoize-one";
import { useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import { CertificateRecord, listCertificatesAsync } from "api/certificate-management";
import { OverrideExpirationButton } from "./OverrideExpirationButton";
import { Card } from "@civicplus/preamble-ui/lib/Card";
import { ButtonGroup } from "@civicplus/preamble-ui/lib/ButtonGroup";
import { Button } from "@civicplus/preamble-ui/lib/Button";
import { formatDistance } from "date-fns";
import Fade from "@material-ui/core/Fade";
import { Alert } from "@material-ui/lab";
import { CertificateRequestsListing } from "./CertificateRequestsListing";
import RefreshIcon from '@material-ui/icons/Refresh';
import { Grid } from "@civicplus/preamble-ui/lib/Grid";

const options = {
    pagination: true,
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    sortOrder: { name: 'ExpirationDate', direction: 'descending' },
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100]
};

const isLetsEncrypt = (issuer: string) => issuer != null &&
    (issuer.indexOf("O=Let's Encrypt") > -1 || issuer.indexOf("(STAGING) Let's Encrypt") > -1);
const certificatesForAdvancedTable = memoize((certificates: CertificateRecord[]) => {
    return certificates.map(certificate => {
        return [
            certificate.siteId,
            certificate.domainName,
            certificate.id,
            certificate.certificateThumbprint,
            certificate.issuer,
            isLetsEncrypt(certificate.issuer) ? "Let's Encrypt" : "Other",
            certificate.expirationDateEpoch,
            certificate.expirationDate,
            certificate.expirationDateEpoch
        ];
    });
});

export const CertificatesListing: React.FC = () => {
    const [certificates, setCertificates] = useState<CertificateRecord[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const fetchDataAsync = async () => {
        try {
            setIsUpdating(true);
            const result = await listCertificatesAsync();
            setCertificates(result);
            setIsLoading(false);
            setIsUpdating(false);
        } catch (ex: any) {
            if (ex && ex.response && ex.response.status === 403) {
                window.location.assign("/403");
                return;
            }
        }
    }
    useEffect(() => {
        fetchDataAsync();
    }, [setCertificates]); // Or [] if effect doesn't need props or state

    const renderAutomaticRenewalButton = (value: any[], tableData: any) => {
        const expirationDate = tableData.rowData[tableData.columnIndex];
        if (isLetsEncrypt(tableData.rowData[ISSUER_INDEX])) {
            return <OverrideExpirationButton
                currentExpirationDate={expirationDate ? new Date(expirationDate) : new Date()}
                onSetCallback={fetchDataAsync}
                certificateId={tableData.rowData[CERTIFICATE_ID_INDEX]} />;
        }
    };

    const renderExpirationDate = (value: any[], tableData: any) => {
        try {
            const rawExpirationDate = tableData.rowData[tableData.columnIndex];
            const expirationDate = new Date(rawExpirationDate);
            if (!rawExpirationDate || !expirationDate) {
                return;
            }
            const differenceInTime = expirationDate.getTime() - new Date().getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            const isWithinRenewalPeriod = differenceInDays < 30 && differenceInDays > 0;
            const isExpired = differenceInDays < 0;
            const expirationDateStyles = isExpired
                ? { color: '#CCC' }
                : isWithinRenewalPeriod
                    ? { color: 'orange', fontWeight: 'bold' } :
                    { color: 'green' };
            const expirationDateText = rawExpirationDate ? formatDistance(expirationDate, new Date(), { addSuffix: true }) : "";
            return <span style={expirationDateStyles} title={rawExpirationDate ? expirationDate.toLocaleDateString() : "Unknown"}>{expirationDateText}</span>;
        } catch (ex) {
            console.error(ex);
            return "Error parsing expiration time."
        }
    };

    const ISSUER_INDEX = 4;
    const CERTIFICATE_ID_INDEX = 2;
    const columns = [
        {
            name: "Site Id",
            label: "Site Id",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "Domain",
            label: "Domain",
            flex: 1,
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Certificate Id",
            label: "Certificate Id",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "Certificate Thumbprint",
            label: " CertificateThumbprint",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "Issuer",
            label: " Issuer",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "Authority",
            label: " Authority",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: true
            }
        },
        {
            name: "ExpirationDate",
            label: " Expiration Date",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                customBodyRender: renderExpirationDate
            }
        },
        {
            name: "Full Expiration Date",
            label: " Full Expiration Date",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "Action",
            label: " Action",
            flex: 1,
            options: {
                filter: true,
                customBodyRender: renderAutomaticRenewalButton
            }
        }
    ];


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <CertificateRequestsListing/>
            </Grid>
            <Grid item xs={12}>
            {isLoading ? (
                <Loader>Loading...</Loader>
            ) : (
                <Fade in>
                    <Card
                        id="active-certificates-card"
                        content={
                            <>
                            <Alert id="message-warning" severity="warning">
                                Overriding Expiration should only be done for testing automatic renewals.
                            </Alert>
                                <AdvancedTable
                                    title="Active Certificates"
                                    columns={columns}
                                    compact={true}
                                    data={certificatesForAdvancedTable(certificates)}
                                    options={options}
                                />
                                <ButtonGroup layout="right"><Button startIcon={<RefreshIcon/>} title="Refresh" disabled={isUpdating} onClick={fetchDataAsync}>Refresh</Button></ButtonGroup>
                            </>
                        }
                        footer={<Alert style={{ width: "100%" }} severity="info" title="">
                            <strong>Let&apos;s Encrypt Certificates should automatically renew 30 days ahead of expiration, and should be valid for 90 days.</strong>
                            <ul>
                                <li>Green text indicates more that 60 days left on validity.</li>
                                <li>Orange text indicates a cert within 30 days of expiration.</li>
                            </ul>
                        </Alert>}
                    />
                </Fade>
            )}
            </Grid>
        </Grid>
    );
};
