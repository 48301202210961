import { executeRequestAsyncFor, executeFormRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/domains`
	},
	get: {
		method: "GET",
		path: `${getApiUrl()}/api/domains/{{id}}`
	},
	create: {
		method: "POST",
		path: `${getApiUrl()}/api/domains`
	},
	update: {
		method: "PUT",
		path: `${getApiUrl()}/api/domains/{{id}}`
	},
	delete: {
		method: "DELETE",
		path: `${getApiUrl()}/api/domains/{{id}}`
	},
	setAsMainDomain: {
		method: "POST",
		path: `${getApiUrl()}/api/domains/setasmaindomain/{{id}}`
	}
};

const getFormData = domain => {
	const formData = new FormData();
	formData.append("DomainName", domain.domainName);
	formData.append("SiteId", domain.siteId);
	if (domain.certificates && domain.certificates.length > 0) {
		domain.certificates.forEach((cert, index) => {
			if (cert.isDeleted && !cert.id) {
				// if the cert was deleted before be saved in the database
				return;
			}
			formData.append(`Certificates[${index}].PfxPassword`, cert.pfxPassword || "");
			formData.append(`Certificates[${index}].File`, cert.file);
			formData.append(`Certificates[${index}].IsActive`, cert.isActive !== undefined ? cert.isActive : false);
			formData.append(`Certificates[${index}].IsDeleted`, cert.isDeleted !== undefined ? cert.isDeleted : false);
			if (cert.file) {
				//when the cert already exists there is not file
				formData.append(`Certificates[${index}].FileName`, cert.file.name);
			}
			if (cert.id) {
				//During editing domain we send back the array, we use the id to determine whether the cert is new or not
				formData.append(`Certificates[${index}].Id`, cert.id);
			}
		});
	}

	if (domain.certificateRequests && domain.certificateRequests.length > 0) {
		// formData.append(`CertificateRequests`, JSON.stringify(certificateRequests));

		domain.certificateRequests.forEach((request, index) => {
			formData.append(`CertificateRequests[${index}].CertificateAuthority`, request.certificateAuthority);
			if (domain.id) {
				formData.append(`CertificateRequests[${index}].DomainId`, domain.id);
			}
			if (request.id) {
				formData.append(`CertificateRequests[${index}].Id`, request.id);
			}
		});
	}

	return formData;
};

export const createDomainAsync = domain => {
	return executeFormRequestAsyncFor(endpoints.create, domain, getFormData(domain));
};

export const updateDomainAsync = domain => {
	return executeFormRequestAsyncFor(endpoints.update, domain, getFormData(domain));
};

export const deleteDomainAsync = id => {
	return executeRequestAsyncFor(endpoints.delete, { id });
};

export const getDomainByIdAsync = id => {
	return executeRequestAsyncFor(endpoints.get, { id });
};

export const setMainSSLDomain = id => {
	return executeRequestAsyncFor(endpoints.setAsMainDomain, { id });
};
