import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete/Autocomplete";
import { fetchSitesAsync } from "api/sitesApi";
import { useEffect, useState, useCallback } from "react";


export interface SiteSelectorDropdownProps{
    placeholder: string
    value: string,
    onChange: (siteId: string) => void
}

export const SiteSelectorDropdown: React.FC<SiteSelectorDropdownProps> = ({onChange, value}: SiteSelectorDropdownProps) => {
    const [sites, setSites] = useState<any[]>([]);

    useEffect(() => {
        const init = async () => {
            const listSitesResponse = await fetchSitesAsync();

            const sitesForDropdown = listSitesResponse.data.map((site:any) => ({ value: site.id, label: site.name}));

            setSites(sitesForDropdown);
        };
        init();
    }, [setSites])

    const onChangeCallback = useCallback((e:any) => {
        onChange && onChange(e.value);
    }, [onChange]);
    return <Autocomplete
					id="base-site-for-copy"
					label="Base Site for Setup"
					value={sites.find(s => s.value == value)?.label}
					onChange={onChangeCallback}
					options={sites}
                    helperText="Base site is used as a copy source and will not be affected. This is not required. If not set this will use the configured common base site."
				/>
};