import Button from "@civicplus/preamble-ui/lib/Button";
import InputLabel from "@civicplus/preamble-ui/lib/InputLabel";
import RadioGroup from "@civicplus/preamble-ui/lib/RadioGroup";
import Delete from "@material-ui/icons/Delete";
import { useCallback } from "react";

const LabelForRadio = ({ id, text, onDelete }) => (
	<span>
		{text}
		<Button
			type="icon"
			size="small"
			onClick={() => {
				onDelete(id);
			}}
		>
			<Delete />
		</Button>
	</span>
);

export const Certificates = ({ certificates, onChange, onDelete }) => {
	const getOptionValue = useCallback(() => {
		if (!certificates) {
			return "";
		}
		const activeCert = certificates.find(x => x.isActive);
		return activeCert ? activeCert.id || activeCert.file.preview : "";
	}, [certificates]);

	const getCertificateOptions = useCallback(() => {
		if (!certificates) {
			return [];
		}
		return certificates
			.filter(x => !x.isDeleted)
			.map(cert => {
				const { file, certificateThumbprint, id, issuer, expirationDate } = cert;
				const optionId = file ? file.preview : id;
				let text = "Issuer: {{certificateIssuer}}, Thumbprint: {{thumbprint}}, Expiration Date: {{expirationDate}}";

				// On new uploads we just have the file, so just use the file name
				if (file) {
					text = "Filename: " + file.name;
				} else {
					// Otherwise, in dev, we won't have a certificate thumbprint, as we get that from azure key vault
					// so use the id for the cert.
					if (!certificateThumbprint) {
						text = "Id: " + id;
					} else {
						text = text.replace("{{certificateIssuer}}", issuer || "");
						text = text.replace("{{thumbprint}}", certificateThumbprint || "");
						text = text.replace("{{expirationDate}}", expirationDate || "");
					}
				}

				return {
					id: optionId,
					value: file ? file.preview : id,
					label: <LabelForRadio id={optionId} text={text} onDelete={onDelete} />
				};
			});
	}, [certificates, onDelete]);

	const certificateOptions = getCertificateOptions();

	if (certificateOptions.length === 0) {
		return null;
	}

	return (
		<>
			<InputLabel>Certificates</InputLabel>
			<RadioGroup
				id="domain-certificateOptions"
				name="Certificate Options"
				options={certificateOptions}
				value={getOptionValue()}
				onChange={onChange}
			/>
		</>
	);
};
