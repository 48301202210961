import { executeRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/DatabaseManagement`
	},
	delete: {
		method: "DELETE",
		path: `${getApiUrl()}/api/DatabaseManagement/{{databaseServer}}/{{databaseName}}`
	},
	queryDbs: {
		method: "POST",
		path: `${getApiUrl()}/api/DatabaseManagement/{{databaseServer}}/?includePrecreatedDbs={{includePrecreatedDbs}}`
	}
};

export const listDatabasesAsync = async (): Promise<DatabaseInfo[]> => {
	const result = await executeRequestAsyncFor(endpoints.list);
	return result.data;
};

export const deleteDatabaseAsync = (database: DatabaseInfo): Promise<void> => {
	return executeRequestAsyncFor(endpoints.delete, { databaseServer: database.server, databaseName: database.name });
};

export const executeQueryOnDbsAsync = async (
	queryRequest: QueryRequest,
	databaseServer: string,
	includePrecreatedDbs: boolean
): Promise<QueryResponse> => {
	const result = await executeRequestAsyncFor(endpoints.queryDbs, {
		databaseServer: databaseServer,
		includePrecreatedDbs: includePrecreatedDbs,
		...queryRequest
	});
	return result.data;
};

interface DatabaseInfo {
	server: string;
	name: string;
	tags: [string, string][];
}

export interface QueryRequest {
	query: string | undefined;
	parameters: Record<string, string>;
}

export interface QueryResponse {
	totalDatabases: number;
	data: Record<string, QueryResult>;
}
interface QueryResult {
	result: any[];
	errorMessage: string;
	isSuccessful: boolean;
}
