import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import React, { ReactNode, useState } from "react";

export interface ConfirmationModalProps {
	onConfirm?: () => void;
	onCancel?: () => void;
	onClose?: () => void;
	open: boolean;
	title: string;
	content: ReactNode;
	fullWidth?: boolean;
	fullHeight?: boolean;
	confirmButtonText?: string;
	"data-testid"?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	onConfirm,
	onCancel,
	onClose,
	open,
	title,
	content,
	fullWidth = true,
	fullHeight = true,
	confirmButtonText = "Confirm",
	...props
}) => {
	const [disableButtons, setDisableButtons] = useState<boolean>(false);

	const onModalConfirm = async () => {
		setDisableButtons(true);

		if (onConfirm) {
			await onConfirm();
		}
		setDisableButtons(false);
	};

	const onModalCancel = async () => {
		setDisableButtons(true);
		if (onCancel) {
			await onCancel();
		}
		setDisableButtons(false);
	};

	const onModalClose = async () => {
		if (onClose) {
			await onClose();
		}
	};

	return (
		<Dialog
			id="confirmation-dialog"
			maxWidth="sm"
			fullWidth={fullWidth}
			fullHeight={fullHeight}
			onClose={onModalClose}
			open={open}
			title={title}
			actions={
				<>
					<Button disabled={disableButtons} onClick={onModalCancel}>
						Cancel
					</Button>
					<Button disabled={disableButtons} color={"primary"} onClick={onModalConfirm}>
						{confirmButtonText}
					</Button>
				</>
			}
		>
			<div data-testid={props["data-testid"]}>{content}</div>
		</Dialog>
	);
};
