import PreambleLoader from "@civicplus/preamble-ui/lib/Loader";

const Loader = props => {
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%,-50%)"
			}}
		>
			<PreambleLoader size={100} thickness={10} />
			{props.text}
		</div>
	);
};

export default Loader;
