import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import CodeEditor from "@civicplus/preamble-ui/lib/CodeEditor";
import Divider from "@civicplus/preamble-ui/lib/Divider";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { hitSitesRouteAsync, introspectionAsync } from "api/diagnosticsApi";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";

const predefinedRoutes = [
	{ label: "Rebuild Cache", value: "/Diagnostics/RebuildCache" },
	{ label: "Auto Save Style Sheet", value: "/DesignCenter/AutoSaveStyleSheet/Execute" }
];

interface SelectedRoute {
	label: string;
	value: string;
}

const useStyles = makeStyles(() => ({
	container: {
		marginTop: 30
	},
	button: {
		marginTop: 20
	}
}));

const Diagnostics: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [selectedRoute, setSelectedRoute] = useState<SelectedRoute>();
	const [introspectionResult, setIntrospectionResult] = useState(null);
	const onRouteChanged = async (selected: any) => {
		setSelectedRoute(selected);
	};
	const onClickHitRoute = async () => {
		try {
			if (selectedRoute && selectedRoute.value) {
				history.push(`/engage6/operations/progress/diagnostics/hitRoute`);
				await hitSitesRouteAsync(selectedRoute.value);
			}
		} catch (ex: any) {
			console.error(ex);
			if (ex.message !== "Network Error") {
				// Ignore request timeout error
				const defaultMessage = "Something went wrong when running the request.";
				enqueueSnackbar(getErrorMessageFromResponse(ex, ex.message || defaultMessage), {
					variant: "error"
				});
			}
		}
	};

	const onClickIntrospection = async () => {
		try {
			setIntrospectionResult(await introspectionAsync());
		} catch (ex: any) {
			console.error(ex);
			if (ex.message !== "Network Error") {
				// Ignore request timeout error
				const defaultMessage = "Something went wrong when running the request.";
				enqueueSnackbar(getErrorMessageFromResponse(ex, ex.message || defaultMessage), {
					variant: "error"
				});
			}
		}
	};

	return (
		<div>
			<Typography variant="h5">Diagnostics</Typography>
			<Divider />
			<Grid container spacing={3} className={classes.container}>
				<Grid item xs={12}>
					<Typography variant="h6">Hit a url for all sites</Typography>
				</Grid>
				<Grid item xs={9}>
					<Autocomplete
						id="keywords"
						placeholder="Start to type..."
						label="Route"
						onChange={onRouteChanged}
						allowOptionCreation
						helperText="Write the route you want to hit for all sites."
						options={predefinedRoutes}
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						disabled={!selectedRoute || !selectedRoute.value}
						onClick={onClickHitRoute}
						className={classes.button}
					>
						Hit Route
					</Button>
				</Grid>
				<Grid item xs={9}>
					<Typography variant="h6">Authentication Introspection</Typography>
				</Grid>
				<Grid item xs={3}>
					<Button onClick={onClickIntrospection} className={classes.button}>
						Introspect
					</Button>
				</Grid>
				{introspectionResult && (
					<Grid item xs={12}>
						<CodeEditor
							width={"100%"}
							value={JSON.stringify(introspectionResult, null, "\t")}
							readOnly
							language="json"
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Diagnostics;
