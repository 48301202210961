import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import Button from "@civicplus/preamble-ui/lib/Button";
import IconCircleCheck from "@civicplus/preamble-ui/lib/Icons/IconCircleCheck";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import memoize from "memoize-one";
import { withSnackbar } from "notistack";
import React from "react";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { setMainSSLDomain } from "../../../api/domains";
import { ConfirmationModal } from "../../modal/ConfirmationModal";
import DomainForm from "./DomainForm";

const defaultEmptyDomain = {};

class DomainList extends React.PureComponent {
	state = {
		openAddDomain: false,
		currentDomain: defaultEmptyDomain,
		openConfirmModal: false
	};

	onRefreshDomainAsync = async domainId => {
		const updatedDomain = await this.props.onRefreshDomain(domainId);
		this.setState({
			currentDomain: updatedDomain
		});
	};

	buildActionMenuFor = domain => {
		const { id, isPrimarySslDomain, certificates } = domain;
		const activeCertificate = certificates.find(c => c.isActive);
		const actions = [
			{
				display: "Modify",
				action: () => {
					this.onEditClick(id);
				}
			}
		];

		if (!isPrimarySslDomain && activeCertificate) {
			actions.push({
				display: "Set Main SSL Domain",
				action: () =>
					this.setState({ openConfirmModal: true, currentDomain: this.props.domains.find(d => d.id === id) })
			});
		}

		actions.push({
			display: "Remove",
			action: () => this.props.onRemoveDomain && this.props.onRemoveDomain(id)
		});
		return actions;
	};

	onEditClick = id => {
		this.setState({
			openAddDomain: true,
			currentDomain: this.props.domains.find(d => d.id === id)
		});
	};

	onAddClick = () => {
		this.setState({ openAddDomain: true, currentDomain: defaultEmptyDomain });
	};

	onSaveDomain = domain => {
		const { domains, onSaveDomain } = this.props;

		const existingDomain = domains.find(d => d.domainName === domain.domainName);
		if (existingDomain) {
			// If we are not updating the existing domain, throw an error as we cannot have duplicates
			if (domain.id !== existingDomain.id) {
				return {
					domainName: {
						errorMessage: "This domain already exists."
					}
				};
			}
		}
		onSaveDomain && onSaveDomain(domain);
		return null;
	};

	getDomainDataForAdvancedTable = memoize(domain => {
		const { id, domainName, isPrimarySslDomain } = domain;

		return [
			domainName,
			isPrimarySslDomain ? <IconCircleCheck /> : "",
			!this.props.isNewSite && (
				<Menu key={id} id={`${domainName}-action-menu`} type="action" items={this.buildActionMenuFor(domain)} />
			)
		];
	});

	mapDomainsForAdvancedTable = () => {
		const { domains } = this.props;
		const data = domains.map(domain => this.getDomainDataForAdvancedTable(domain));
		return data;
	};

	columns = [
		{
			name: "Name",
			label: "Name",
			options: {
				filter: true,
				sort: false
			}
		},
		{
			name: "isPrimarySslDomain",
			label: "Main SSL Domain",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "", // for action menus
			label: "",
			options: {
				filter: false,
				sort: false,
				viewColumns: false
			}
		}
	];

	options = {
		pagination: false,
		responsive: "vertical",
		onRowsDelete: rowsDeleted => {
			const domainsToRemove = this.props.domains.filter((x, index) =>
				rowsDeleted.data.find(y => y.dataIndex === index)
			);
			this.props.onRemoveDomains(domainsToRemove);
		},
		selectableRows: "none"
	};

	onAddDomainClose = () => {
		this.setState({ openAddDomain: false, currentDomain: {} });
	};

	onConfirmSetMainDomain = async () => {
		try {
			await setMainSSLDomain(this.state.currentDomain.id);
			this.props.enqueueSnackbar("Successfully settled main domain", { variant: "success" });
			this.props.onSetDomainAsMain && this.props.onSetDomainAsMain();
		} catch (ex) {
			console.error(ex);
			this.props.enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to set domain as main SSL."), {
				variant: "error"
			});
		}
		this.setState({ openConfirmModal: false, currentDomain: defaultEmptyDomain });
	};

	onCancelSetMainDomain = () => {
		this.setState({ openConfirmModal: false, currentDomain: defaultEmptyDomain });
	};

	render() {
		return (
			<>
				{!this.props.isNewSite && (
					<Button id="domain-add" onClick={this.onAddClick}>
						Add Domain
					</Button>
				)}
				<AdvancedTable
					id="domains-list"
					title="Domains"
					columns={this.columns}
					data={this.mapDomainsForAdvancedTable()}
					options={this.options}
				/>
				<ConfirmationModal
					open={this.state.openConfirmModal}
					title="Set Main SSL Domain"
					content={`Are you sure you want to set ${this.state.currentDomain.domainName} domain as Main SSL. The change to login with new domain might take up to 5 minutes.`}
					onConfirm={this.onConfirmSetMainDomain}
					onCancel={this.onCancelSetMainDomain}
					onClose={this.onCancelSetMainDomain}
					fullHeight={false}
				/>
				<DomainForm
					open={this.state.openAddDomain}
					onSaveDomain={this.onSaveDomain}
					data={this.state.currentDomain}
					onClose={this.onAddDomainClose}
					onRefreshDomain={this.onRefreshDomainAsync}
				/>
			</>
		);
	}
}

export default withSnackbar(DomainList);
