import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete";
import BreadCrumbs from "@civicplus/preamble-ui/lib/Breadcrumbs";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Divider from "@civicplus/preamble-ui/lib/Divider";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import PreambleLoader from "@civicplus/preamble-ui/lib/Loader";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import enhanceWithValidation, {
	regExpValidation,
	requiredValidation
} from "@civicplus/preamble-ui/lib/Validations/index";
import { fetchAllOrganizations, fetchHcmsAppsAsync, fetchHcmsEnvironments } from "api/hcmsAppApi";
import userManager from "api/userManager";
import { CreateAppModal } from "components/modal/CreateAppModal";
import { produce } from "immer";
import isEqual from "lodash/isEqual";
import memoize from "memoize-one";
import { withSnackbar } from "notistack";
import React, { createRef } from "react";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { createDomainAsync, deleteDomainAsync, getDomainByIdAsync, updateDomainAsync } from "../../api/domains";
import { createSiteAsync, fetchSiteAsync, updateSiteAsync } from "../../api/sitesApi";
import { getDatabaseServerName, getDefaultDomain } from "../../utilities/environmentUtilities";
import Loader from "../loader/Loader";
import { ConfirmationModal } from "../modal/ConfirmationModal";
import DomainList from "./domain-management/DomainList";
import { serviceLevelOptions } from "./serviceLevel";
import { SiteSelectorDropdown } from "./SiteSelectorDropdown";

const TextInputWithValidation = enhanceWithValidation(TextInput);
const AutocompleteWithValidation = enhanceWithValidation(Autocomplete);

class ManageSiteForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			siteFetched: false,
			site: null,
			baseSiteForCopy: null,
			originalSite: null, // Hold the site data from the API as a readonly state
			domains: [],
			hcmsConfiguration: {},
			isNew: false,
			isSaving: false,
			modalProps: {
				open: false,
				domainToRemove: {}
			},
			error: false,
			environments: [],
			apps: [],
			orgs: [],
			selectedEnvironment: null,
			selectedApp: null,
			selectedOrg: null,
			isLoadingApps: false,
			isLoadingOrgs: true,
			showCreateAppDialog: false
		};

		this.siteNameRef = createRef();
		this.siteIdRef = createRef();
		this.databaseServerRef = createRef();
		this.databaseNameRef = createRef();
		this.oldSiteIdRef = createRef();
		this.serviceLevelRef = createRef();
		this.environmentRef = createRef();
		this.appNameRef = createRef();
		this.organizationRef = createRef();
		this.baseSiteForCopyRef = createRef();

		this.validationRefs = [
			this.siteNameRef,
			this.siteIdRef,
			this.databaseServerRef,
			this.databaseNameRef,
			this.oldSiteIdRef,
			this.serviceLevelRef,
			this.environmentRef,
			this.appNameRef,
			this.organizationRef
		];
	}

	/**
	 * Returns whether or not the data has been modified by the user
	 * We do not compare the site domains as they are saved on their own
	 */
	isClean = () => {
		const siteDataClone = { ...this.state.site };
		delete siteDataClone.domains;

		const originalSiteDataClone = { ...this.state.originalSite };
		delete originalSiteDataClone.domains;

		return isEqual(siteDataClone, originalSiteDataClone);
	};

	loadSiteDataAsync = async () => {
		const response = await fetchSiteAsync(this.props.siteId);

		const site = response.data;
		// Remove domains from the site object so we can deal with them independently
		const domains = [...site.domains];
		delete site.domains;
		this.setState({ site, originalSite: site, domains, siteFetched: true });
	};

	getEnvironmentsListAsync = async () => {
		try {
			const envList = [];
			const relevantEnvironments = await fetchHcmsEnvironments();
			relevantEnvironments.data.forEach(o => {
				envList.push({ label: o, value: o });
			});
			this.setState({ environments: envList });
		} catch (ex) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			this.onError(
				(ex.response && ex.response.data && ex.response.data.message) || "Failed to fetch HCMS environments."
			);
		}
	};

	getAppsListAsync = async (env, orgId) => {
		const apps = await fetchHcmsAppsAsync(env, orgId);
		this.setState({ apps, isLoadingApps: false });
		return apps;
	};

	getOrganizationsListAsync = async () => {
		try {
			const orgs = await fetchAllOrganizations();
			const data = orgs.data.sort((a, b) => a.name.localeCompare(b.name));
			this.setState({ orgs: data, isLoadingOrgs: false });
		} catch (ex) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			this.onError((ex.response && ex.response.data && ex.response.data.message) || "Failed to fetch Organizations.");
		}
	};

	async componentDidMount() {
		await userManager.initAsync();
		if (this.props.siteId === "new") {
			const databaseServer = getDatabaseServerName();
			this.setState({
				site: {
					databaseServer: databaseServer
				},
				originalSite: {
					databaseServer: databaseServer
				},
				domains: [{ domainName: getDefaultDomain() }],
				siteFetched: true,
				isNew: true
			});

			this.getEnvironmentsListAsync();
			this.getOrganizationsListAsync();
			return;
		}
		try {
			await this.loadSiteDataAsync();
		} catch (ex) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			console.error(ex);
			this.setState({
				siteFetched: true
			});
			this.onError((ex.response && ex.response.data && ex.response.data.message) || "Failed to fetch site.");
		}
	}

	componentWillUnmount() {
		window.onbeforeunload = undefined;
	}

	componentDidUpdate() {
		if (!this.isClean()) {
			window.onbeforeunload = () => true;
		}
	}

	onChangeSite = memoize(name => e => {
		const site = produce(this.state.site, draft => {
			draft[name] = e.value || (e.target.value.trim() ? e.target.value : e.target.value.trim());
			return draft;
		});
		this.setState({
			site
		});
	});

	onChangeEnvironment = async e => {
		const selectedEnvironment = this.state.environments.find(env => env.value == e.value);
		const selectedOrg = this.state.selectedOrg;
		this.setState({ selectedEnvironment });

		if (selectedOrg) {
			this.setState({ isLoadingApps: true });
			this.getAppsListAsync(selectedEnvironment.value, selectedOrg.id);
		}
	};

	onChangeApplication = async e => {
		const selectedApp = this.state.apps.find(app => app.id == e.value);
		const hcmsConfiguration = { environment: this.state.selectedEnvironment.value, appName: selectedApp.name };
		this.setState({ selectedApp, hcmsConfiguration });
	};

	onChangeBaseSiteForCopy = async value => {
		this.setState({ baseSiteForCopy: value });
	};

	onChangeOrganization = async e => {
		const selectedOrg = this.state.orgs.find(org => org.id == e.value);
		const selectedEnvironment = this.state.selectedEnvironment;
		this.setState({ selectedOrg });

		if (selectedEnvironment) {
			this.setState({ isLoadingApps: true });
			const apps = await this.getAppsListAsync(selectedEnvironment.value, selectedOrg.id);
			if (apps.length == 1) {
				this.setState({ selectedApp: apps[0] });
			} else {
				this.setState({ selectedApp: null });
			}
		}
	};

	onRefreshDomainAsync = async domainId => {
		const updatedDomain = (await getDomainByIdAsync(domainId)).data;
		const index = this.state.domains.findIndex(x => x.id === domainId);
		if (index > -1) {
			this.setState(prevState => {
				prevState.domains[index] = updatedDomain;
				return { ...prevState, domains: [...prevState.domains] };
			});
		}

		return updatedDomain;
	};

	onSaveDomainAsync = async domain => {
		const { domains, site } = this.state;
		const domainIndex = domains.findIndex(d => d.id === domain.id);
		try {
			let successMessage;
			if (domainIndex > -1) {
				//edit
				await updateDomainAsync(domain);
				successMessage = `${domain.domainName} was successfully modified.`;
			} else {
				//create
				await createDomainAsync({
					...domain,
					siteId: site.id
				});

				successMessage = `${domain.domainName} was successfully created.`;
			}

			this.onSuccess(successMessage);
			//reload data from server
			await this.loadSiteDataAsync();
		} catch (error) {
			this.onError(error, "Failed to save domain");
		}
	};

	onSetDomainAsMainAsync = async () => {
		await this.loadSiteDataAsync();
	};

	onRemoveDomain = id => {
		const domain = this.state.domains.find(d => d.id === id);
		this.setState({
			modalProps: {
				open: true,
				domainToRemove: domain
			}
		});
	};

	onRemoveDomainAsync = async domain => {
		try {
			await deleteDomainAsync(domain.id);
			this.setState(prevState => {
				const domains = prevState.domains;
				return {
					...prevState,
					domains: domains.filter(x => x.id !== domain.id)
				};
			});
			this.onSuccess(`${domain.domainName} was successfully deleted.`);
		} catch (error) {
			this.onError(error, "Failed to delete domain.");
		}
	};

	onConfirm = async () => {
		const { domainToRemove } = this.state.modalProps;
		this.onRemoveDomainAsync(domainToRemove);
		this.setState({
			modalProps: {
				...this.state.modalProps,
				open: false,
				domainToRemove: {}
			}
		});
	};

	onCancel = () => {
		this.setState({
			modalProps: {
				...this.state.modalProps,
				open: false,
				domainToRemove: {}
			}
		});
	};

	onSuccess = message => {
		this.props.enqueueSnackbar(message, { variant: "success" });
	};

	onError = (ex, defaultMessage) => {
		console.error(ex);
		this.setState({
			isSaving: false
		});
		this.props.enqueueSnackbar(getErrorMessageFromResponse(ex, defaultMessage), {
			variant: "error"
		});
	};

	saveAsync = async () => {
		this.setState({ isSaving: true }, async () => {
			const { site, hcmsConfiguration, baseSiteForCopy } = this.state;

			try {
				let response;
				// Do not ask the user if they want to leave the site after saving
				window.onbeforeunload = undefined;
				if (this.state.isNew) {
					this.props.history.push(`/engage6/operations/progress/createSite/${site.name}`);
					const organizationId = this.state.selectedOrg?.id;
					const createSiteRequest = {
						siteName: site.name,
						baseSiteIdForSetup: baseSiteForCopy,
						databaseServer: site.databaseServer,
						databaseName: site.databaseName || site.name,
						hcmsConfiguration: hcmsConfiguration,
						allowListRanges: site.allowListRanges,
						denyListRanges: site.denyListRanges,
						serviceLevelStage: site.serviceLevelStage,
						organizationId: organizationId
					};
					response = await createSiteAsync(createSiteRequest);
				} else {
					const updateSiteRequest = {
						siteName: site.name,
						baseSiteIdForSetup: baseSiteForCopy,
						databaseServer: site.databaseServer,
						databaseName: site.databaseName,
						allowListRanges: site.allowListRanges,
						denyListRanges: site.denyListRanges,
						serviceLevelStage: site.serviceLevelStage
					};
					response = await updateSiteAsync(updateSiteRequest, site.id);

					this.setState(
						{
							site: response.data,
							originalSite: response.data,
							isNew: false,
							isSaving: false
						},
						() => {
							this.props.history.push(`/engage6/sites/${response.data.id}`);
						}
					);
				}
			} catch (ex) {
				this.onError(ex, "Failed to save site.");
			}
		});
	};

	checkValidation = async () => {
		let isValid = true;
		const validationResultsPromise = this.validationRefs.map(async ref => {
			if (ref.current) {
				const result = await ref.current.validate();
				if (result.error) {
					isValid = false;
				}
			}
		});

		await Promise.all(validationResultsPromise);
		return isValid;
	};

	getPublishedDomains = memoize(domains => domains.filter(domain => !domain.isDeleted));
	onCreateAppDialogClose = () => {
		this.setState({ showCreateAppDialog: false });
	};

	onCreateAppDialogSave = async appId => {
		this.setState({ showCreateAppDialog: false });
		if (this.state.selectedOrg && this.state.selectedEnvironment) {
			this.setState({ isLoadingApps: true });
			const apps = await this.getAppsListAsync(this.state.selectedEnvironment.value, this.state.selectedOrg.id);
			const selectedApp = apps.find(app => app.id == appId);
			const hcmsConfiguration = { environment: this.state.selectedEnvironment.value, appName: selectedApp?.name };
			this.setState({ selectedApp, hcmsConfiguration });
		}
	};

	renderBody = () => {
		if (this.state.isSaving) {
			return <Loader>Saving Changes.</Loader>;
		}
		return (
			<>
				<CreateAppModal
					id="create-app-modal"
					data-testid="create-app-modal"
					isOpen={this.state.showCreateAppDialog}
					onClose={() => this.onCreateAppDialogClose()}
					onSave={appId => this.onCreateAppDialogSave(appId)}
					hcmsEnv={this.state.selectedEnvironment?.value ?? ""}
					organization={this.state.selectedOrg}
					appName={this.state.site.name || ""}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12} />
					<Grid item xs={4}>
						<Grid container spacing={24}>
							<Grid item xs={12}>
								<TextInputWithValidation
									ref={this.siteNameRef}
									validations={[requiredValidation, regExpValidation]}
									id="siteName"
									variant={"filled"}
									fullWidth={true}
									label="Site Name"
									value={this.state.site.name || ""}
									onChange={e => {
										this.onChangeSite("name")(e);
										if (this.state.isNew) {
											this.setState({
												domains: [{ domainName: `${e.target.value}` + getDefaultDomain() }]
											});
										}
									}}
									regExpPattern={"^[0-9A-Za-z][0-9A-Za-z-]{1,61}[0-9A-Za-z]$"}
									errorMessages={{
										regExpPattern:
											"Site name must have 3-63 alphanumeric characters with optional dashes in the middle."
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInputWithValidation
									ref={this.siteIdRef}
									validations={this.state.isNew ? [] : [requiredValidation]}
									id="siteId"
									variant={"filled"}
									fullWidth={true}
									label="Site Id"
									disabled={true}
									value={this.state.isNew ? "This will be created after saving." : this.state.site.id}
								/>
							</Grid>
							<Grid item xs={12}>
								<AutocompleteWithValidation
									ref={this.serviceLevelRef}
									options={serviceLevelOptions}
									validations={[requiredValidation]}
									id="service-level-stage"
									variant={"outlined"}
									label="Service Level Status"
									onChange={this.onChangeSite("serviceLevelStage")}
									value={serviceLevelOptions.find(s => s.value == this.state.site.serviceLevelStage)}
									placeholder="Select a service level status..."
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInputWithValidation
									ref={this.databaseServerRef}
									validations={[requiredValidation]}
									id="databaseServer"
									variant={"filled"}
									fullWidth={true}
									label="Database Server"
									value={this.state.site.databaseServer}
									onChange={this.onChangeSite("databaseServer")}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInputWithValidation
									ref={this.databaseNameRef}
									validations={[requiredValidation]}
									id="databaseName"
									variant={"filled"}
									fullWidth={true}
									label="Database Name"
									value={this.state.site.databaseName || this.state.site.name || ""}
									onChange={this.onChangeSite("databaseName")}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInput
									id="allowListRanges"
									variant={"filled"}
									fullWidth={true}
									label="AllowList IP Ranges"
									helperText="CSV of IP addresses and/or CIDR blocks. Only the IP Ranges in AllowList will be allowed for the functioning of the site. Any other IP ranges will be blocked. Proceed with caution."
									value={this.state.site.allowListRanges || ""}
									onChange={this.onChangeSite("allowListRanges")}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInput
									id="denyListRanges"
									variant={"filled"}
									fullWidth={true}
									label="DenyList IP Ranges"
									helperText="CSV of IP addresses and/or CIDR blocks. This value is not validated against any AllowList ranges, production or otherwise. Improper usage can affect site functionality. DenyList takes precedence over AllowList."
									value={this.state.site.denyListRanges || ""}
									onChange={this.onChangeSite("denyListRanges")}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInputWithValidation
									ref={this.oldSiteIdRef}
									validations={[]}
									type="number"
									id="oldSiteId"
									variant={"filled"}
									fullWidth={true}
									label="Old CP Tools Site Id"
									value={this.state.site.oldSiteId || ""}
									onChange={this.onChangeSite("oldSiteId")}
								/>
							</Grid>

							{this.state.isNew && (
								<Grid item xs={12}>
									{this.state.isLoadingOrgs && (
										<Grid item xs={12} style={{ marginTop: "1.5em" }}>
											<PreambleLoader size={30} thickness={5} />
										</Grid>
									)}
									{!this.state.isLoadingOrgs && (
										<AutocompleteWithValidation
											ref={this.organizationRef}
											options={this.state.orgs.map(org => {
												return { label: org.name, value: org.id };
											})}
											validations={this.state.isNew ? [requiredValidation] : []}
											required={true}
											id="organizations-stage"
											variant={"outlined"}
											label="Organization"
											onChange={e => this.onChangeOrganization(e)}
											value={this.state.selectedOrg?.id ?? ""}
											placeholder="Select an organization..."
										/>
									)}
								</Grid>
							)}
							{this.state.isNew && (
								<React.Fragment>
									<Grid item xs={12}>
										<Typography variant="h6" gutterBottom>
											Base Site
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<SiteSelectorDropdown placeholder={"St-City Common Base"}
											onChange={this.onChangeBaseSiteForCopy}
											value={this.state.baseSiteForCopy}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="h6" gutterBottom>
											HCMS Configuration
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<AutocompleteWithValidation
											ref={this.environmentRef}
											options={this.state.environments}
											validations={this.state.isNew ? [requiredValidation] : []}
											id="environments-stage"
											variant={"outlined"}
											label="Environment"
											required={true}
											onChange={e => this.onChangeEnvironment(e)}
											value={this.state.selectedEnvironment ? this.state.selectedEnvironment.value : ""}
											placeholder="Select an environment..."
										/>
									</Grid>
									{this.state.isLoadingApps && (
										<Grid item xs={12} style={{ marginTop: "1.5em" }}>
											<PreambleLoader size={30} thickness={5} />
										</Grid>
									)}
									<Grid item xs={12}>
										{this.state.selectedEnvironment && this.state.selectedOrg && !this.state.isLoadingApps && (
											<>
												<AutocompleteWithValidation
													ref={this.appNameRef}
													options={this.state.apps.map(app => {
														return { label: app.name, value: app.id };
													})}
													validations={this.state.isNew ? [requiredValidation] : []}
													id="applications-stage"
													variant={"outlined"}
													label="Application"
													required={true}
													onChange={e => this.onChangeApplication(e)}
													value={
														this.state.selectedApp
															? { label: this.state.selectedApp.name, value: this.state.selectedApp.id }
															: ""
													}
													placeholder="Select an application..."
												/>
												<Button
													id="add-application-button"
													color="primary"
													onClick={() => this.setState({ showCreateAppDialog: true })}
												>
													Add Application
												</Button>
											</>
										)}
									</Grid>
								</React.Fragment>
							)}
						</Grid>
					</Grid>
					<Grid item xs={8}>
						<DomainList
							isNewSite={this.state.isNew}
							domains={this.getPublishedDomains(this.state.domains)}
							onSaveDomain={this.onSaveDomainAsync}
							onRemoveDomain={this.onRemoveDomain}
							onRefreshDomain={this.onRefreshDomainAsync}
							onSetDomainAsMain={this.onSetDomainAsMainAsync}
						/>
					</Grid>
				</Grid>
			</>
		);
	};

	render() {
		if (!this.state.siteFetched) {
			return <Loader />;
		}

		return (
			<>
				<BreadCrumbs id="manageSiteForm-breadcrumbs">
					<Link to="/engage6/sites">Sites</Link>
					<div>{this.state.site.name || "Add Site"}</div>
				</BreadCrumbs>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<ButtonGroup layout="right">
							<Button
								id="site-save"
								disabled={this.isClean() || this.state.isSaving}
								size="small"
								color="primary"
								onClick={async () => {
									if (await this.checkValidation()) {
										this.saveAsync();
									}
								}}
							>
								{this.state.isSaving ? (
									<span>
										Saving <PreambleLoader thickness={8} size={10} />
									</span>
								) : (
									"Save"
								)}
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid item xs={12} />
				</Grid>
				<Divider id="manageSiteForm-divider" />
				{this.renderBody()}
				<Prompt
					when={!this.isClean() && !this.state.isSaving}
					message="Your unsaved changes will be lost. Are you sure you want to leave?"
				/>
				<ConfirmationModal
					{...this.state.modalProps}
					title="Delete"
					content={`Are you sure you want to delete the following domain: ${this.state.modalProps.domainToRemove.domainName}?`}
					onConfirm={this.onConfirm}
					onCancel={this.onCancel}
					onClose={this.onCancel}
					fullHeight={false}
				/>
			</>
		);
	}
}

export default withSnackbar(ManageSiteForm);
