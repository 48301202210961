// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getErrorMessageFromResponse = (ex: any, defaultMessage: string): string => {
	if (ex && ex.response) {
		return ex.response.status === 403
			? "You do not have permission to perform this action."
			: (ex.response.data && ex.response.data.message) || defaultMessage;
	}

	return ex && ex.message ? ex.message : defaultMessage;
};
