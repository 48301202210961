import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
// import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Menu from "@civicplus/preamble-ui/lib/Menu/Menu";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { makeCustomSelectPicker } from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { deleteHcmsApp, deleteOrgServiceApp, fetchHcmsAppsAsync, fetchHcmsTokenAsync } from "api/hcmsAppApi";
import { HcmsApp } from "components/HcmsApps/HcmsApp";
import { HcmsDomains } from "components/HcmsApps/HcmsDomains";
import Loader from "components/loader/Loader";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { CreateAppModal } from "../modal/CreateAppModal";
import { ModifyAppModal } from "../modal/ModifyAppModal";

export const HcmsAppManagement: React.FC = props => {
	const [hasInitialized, setHasInitialized] = useState<boolean>(false);
	const [apps, setApps] = useState<HcmsApp[]>([]);
	const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showModifyDialog, setShowModifyDialog] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [currentApp, setCurrentApp] = useState<{ data: HcmsApp; index: number }>();

	const { enqueueSnackbar } = useSnackbar();

	const fetchApps = useCallback(async () => {
		const env = (props as any).environmentType;
		try {
			const apps = await fetchHcmsAppsAsync(env);
			setApps(apps);
			setIsLoading(false);
		} catch (ex: any) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			console.error(ex);
			setIsLoading(false);
			enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to fetch apps."), {
				variant: "error"
			});
		}
	}, [enqueueSnackbar, props]);

	useEffect(() => {
		if (!hasInitialized) {
			setHasInitialized(true);
			fetchApps();
		}
	}, [enqueueSnackbar, hasInitialized, fetchApps, props]);

	const onCreateDialogSave = useCallback(() => {
		setShowCreateDialog(false);
		fetchApps();
	}, [fetchApps]);

	const onModifyDialogSave = useCallback(() => {
		setShowModifyDialog(false);
		fetchApps();
	}, [fetchApps]);

	const onCreateDialogClose = useCallback(() => {
		setShowCreateDialog(false);
	}, []);

	const onModifyDialogClose = useCallback(() => {
		setShowModifyDialog(false);
	}, []);

	//returns true if the row should be filtered out
	const filterLogic = useCallback((rowValue: any, filteredValues: any[]) => {
		return filteredValues.length > 0 && !filteredValues.map(x => x.value).includes(rowValue);
	}, []);

	async function deleteApp() {
		const currentEnv = (props as any).environmentType;

		try {
			const hcmsT = (await fetchHcmsTokenAsync(currentEnv)).data;
			await deleteHcmsApp(hcmsT, currentEnv, currentApp?.data.name);

			if (currentApp?.data.organization !== undefined) {
				const orgDetails = currentApp?.data.organization.split(",");
				const orgId = orgDetails[0];
				await deleteOrgServiceApp(orgId, currentApp?.data.name, (props as any).environmentType);
			}

			setShowDeleteModal(false);
			fetchApps();
			enqueueSnackbar(`${currentApp?.data.name} has been deleted`, {
				variant: "success"
			});
		} catch (ex: any) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			console.error(ex);
			setIsLoading(false);
			enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to delete app."), {
				variant: "error"
			});
		}
	}

	const getAppDataForAdvancedTable = useCallback(
		(app: HcmsApp, index: number) => {
			const env = (props as any).environmentType;
			const domain = HcmsDomains.get(env);
			const name = app.name;
			const appId = app.id;
			let orgName = "";
			if (app.organization !== undefined) {
				const orgDetails = app.organization.split(",");
				orgName = orgDetails[1];
			}
			return [
				appId,
				name,
				orgName,
				{ domain, name },
				app.defaultRole,
				<Menu
					key={app.id}
					type="action"
					id={`${app.id}-actions`}
					title="Actions"
					items={actionMenuItems(app, index)}
					stopPropagation={true}
				/>
			];
		},
		[props]
	);

	const defaultActionMenuColumn = useMemo(
		() => ({
			name: "actions",
			label: "Actions",
			options: { viewColumns: false, sort: false, searchable: false, filter: false }
		}),
		[]
	);

	function actionMenuItems(item: HcmsApp, index: number) {
		const results = [];

		results.push({
			display: "Modify",
			action: async () => {
				setShowModifyDialog(true);
				setCurrentApp({ data: item, index });
			}
		});

		results.push({
			display: "Delete",
			action: async () => {
				setShowDeleteModal(true);
				setCurrentApp({ data: item, index });
			}
		});

		return results;
	}

	const columns = useMemo(
		() => [
			{ name: "id", label: "App ID", options: { sort: false, filter: false, display: "false" } },
			{
				name: "Name",
				label: "Name",
				options: {
					filter: true,
					sort: true,
					...makeCustomSelectPicker(
						"Name",
						Array.from(new Set(apps.map(c => c.name))).map(c => ({
							label: c,
							value: c
						})),
						filterLogic
					)
				}
			},

			{
				name: "Organization",
				label: "Organization",
				options: {
					filter: true,
					sort: true,
					...makeCustomSelectPicker(
						"Organization",
						Array.from(
							new Set(
								apps.map(c => {
									let dd = "";
									if (c.organization !== undefined && c.organization !== "") {
										dd = c.organization.split(",")[1];
										if (dd !== "" && dd !== undefined) {
											return dd;
										}
									}
								})
							)
						)
							.map(c => {
								return {
									label: c,
									value: c
								};
							})
							.filter(function (element) {
								return element.value !== undefined;
							}),
						filterLogic
					)
				}
			},

			{
				name: "Link",
				label: "Link",
				options: {
					filter: false,
					sort: true,
					customBodyRender: (app: any) => {
						const appLink = `${app.domain}/app/${app.name}`;
						return (
							<a href={appLink} key="app-link" target="blank">
								{appLink}
							</a>
						);
					}
				}
			},
			{
				name: "DefaultRole",
				label: "Default Role",
				options: {
					display: false,
					filter: true,
					sort: true,
					...makeCustomSelectPicker(
						"Default Role",
						Array.from(new Set(apps.map(c => c.defaultRole))).map(c => ({
							label: c,
							value: c
						})),
						filterLogic
					)
				}
			},

			defaultActionMenuColumn
		],
		[apps, defaultActionMenuColumn, filterLogic]
	);

	const options = {
		pagination: false,
		responsive: "vertical",
		selectableRows: "none",
		onRowClick: (data: any) => {
			const selectedApp = apps.filter((app: any) => {
				return app.id === data[0];
			});
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			setCurrentApp({ data: selectedApp[0]!, index: 0 });
			setShowModifyDialog(true);
		},
		customSort: (data: any[], colIndex: number, order: string): any[] => {
			return data.sort((a, b) => {
				//Custom sort for Link Field
				if (colIndex === 3) {
					a = `${a.data[colIndex].domain}/app/${a.data[colIndex].name}`;
					b = `${b.data[colIndex].domain}/app/${b.data[colIndex].name}`;
				}
				//Regular text columns should be sorted normally
				else {
					a = a.data[colIndex].toLowerCase();
					b = b.data[colIndex].toLowerCase();
				}

				if (a === b) return 0;

				return (a < b ? -1 : 1) * (order === "desc" ? 1 : -1);
			});
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div>
				<Button id="site-add" onClick={() => setShowCreateDialog(true)}>
					ADD HCMS APP
				</Button>
			</div>

			<CreateAppModal
				data-testid="create-app-modal"
				isOpen={showCreateDialog}
				onClose={onCreateDialogClose}
				onSave={onCreateDialogSave}
				hcmsEnv={(props as any).environmentType}
			/>

			<ModifyAppModal
				data-testid="modify-app-modal"
				isOpen={showModifyDialog}
				onClose={onModifyDialogClose}
				onSave={onModifyDialogSave}
				hcmsEnv={(props as any).environmentType}
				app={currentApp?.data}
			/>

			<AdvancedTable
				title="Platform Sites and Services"
				columns={columns}
				data={apps.map((app, index) => getAppDataForAdvancedTable(app, index))}
				options={options}
			/>

			<Dialog
				onClose={() => {
					setShowDeleteModal(false);
					fetchApps();
				}}
				open={showDeleteModal}
				actions={[
					<Button
						color="primary"
						onClick={async () => {
							deleteApp();
						}}
						key="deleteAction"
					>
						OK
					</Button>,
					<Button
						onClick={() => {
							setShowDeleteModal(false);
						}}
						key="cancel"
					>
						CANCEL
					</Button>
				]}
				title="Delete"
			>
				<Typography>Are you sure you want to delete this app?</Typography>
			</Dialog>
		</>
	);
};
