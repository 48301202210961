import PropTypes from "prop-types";
import React from "react";
import userManager from "../../api/userManager";
import Loader from "../loader/Loader";

export class SignIn extends React.Component {
	static propTypes = {
		location: PropTypes.shape({
			state: PropTypes.shape({
				pathname: PropTypes.string
			})
		})
	};

	static defaultProps = {
		location: {}
	};

	async componentDidMount() {
		const { from } = this.props.location.state || {
			from: { pathname: "/" }
		};
		await userManager.signIn(from.pathname);
	}

	render() {
		return <Loader>Loading...</Loader>;
	}
}
