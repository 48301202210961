import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import enhanceWithValidation from "@civicplus/preamble-ui/lib/Validations";
import { makeStyles } from "@material-ui/core/styles";
import { createHcmsApp, createOrgServiceApp, fetchAllOrganizations, fetchHcmsTokenAsync } from "api/hcmsAppApi";
import { HcmsApp } from "components/HcmsApps/HcmsApp";
import { Organization } from "components/HcmsApps/Organization";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { AppDetails, OrgServiceAppDetails, ProductUrls } from "../HcmsApps/HcmsTypes";

const useStyles = makeStyles(() => ({
	gridTextWrapper: {
		display: "grid",
		alignItems: "center",
		paddingRight: "0px !important"
	},
	gridIconWrapper: {
		paddingRight: "35px !important"
	},
	btnsContainer: {
		marginTop: 20
	}
}));

const EnhancedAutocomplete = enhanceWithValidation(Autocomplete);
const EnhancedTextInput = enhanceWithValidation(TextInput);

interface CreateAppModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (appId?: string) => void;
	hcmsEnv: string;
	organization?: any;
	appName?: string;
}

export const CreateAppModal: React.FC<CreateAppModalProps> = ({
	isOpen,
	onClose,
	onSave,
	hcmsEnv,
	organization,
	appName
}) => {
	const classes = useStyles();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [orgList, setOrgList] = useState<[]>([]);
	const [isValid, setIsValid] = useState<boolean>(true);
	const { enqueueSnackbar } = useSnackbar();
	const [selectedOrganization, setSelectedOrganization] = useState<{ label: string; value: string }>();
	const [appDetails, setAppDetails] = useState<AppDetails>({
		name: "",
		org: "",
		hmcsEnvironment: "",
		hcmsToken: ""
	});

	const onOrgChange = useCallback((selected: { label: string; value: string }) => {
		setAppDetails(prevState => ({
			...prevState,
			org: `${selected.label},${selected.value}`
		}));
		setSelectedOrganization(selected);
	}, []);

	useEffect(() => {
		const initAsync = async () => {
			if (orgList.length == 0) {
				const orgOptions: any = [];
				const allOrgs = await fetchAllOrganizations();
				const arr = allOrgs.data;

				arr.forEach((o: Organization) => {
					orgOptions.push({ label: o.name, value: o.id });
				});
				setOrgList(orgOptions);
				return arr;
			}
		};

		setAppDetails(prevState => ({
			...prevState,
			org: organization ? `${organization.name},${organization.id}` : prevState.org
		}));

		if (organization) {
			const org: any = orgList.find((o: any) => o.value == organization.id);
			setSelectedOrganization(org);
		}
		initAsync();
	}, [orgList, organization, selectedOrganization]);

	useEffect(() => {
		setAppDetails(prevState => ({
			...prevState,
			hmcsEnvironment: hcmsEnv,
			name: appName?.toLowerCase() || prevState.name
		}));
	}, [appName, hcmsEnv]);

	const onSaveDialog = useCallback(async () => {
		setDisabled(true);
		if (!isValid) {
			enqueueSnackbar(`Please provide a valid application name.`, {
				variant: "error"
			});
			setDisabled(false);
			return;
		}
		let appId: any;
		try {
			const hcmsEnvironment = !appDetails.hmcsEnvironment ? hcmsEnv : appDetails.hmcsEnvironment;
			const hcmsT = (await fetchHcmsTokenAsync(hcmsEnvironment)).data;
			appDetails.hcmsToken = hcmsT;
			const hcmsApp = await createHcmsApp(appDetails);
			const typedApp = hcmsApp.data as HcmsApp;
			appId = typedApp.id;
			const orgServiceApp: OrgServiceAppDetails = {
				id: typedApp.id,
				name: typedApp.name,
				organizationId: typedApp.organization.split(",")[0],
				productType: 6,
				productUri: (ProductUrls as any)[hcmsEnv]
			};

			await createOrgServiceApp(orgServiceApp);
			enqueueSnackbar(`"${appDetails.name}" has been created.`, {
				variant: "success"
			});
		} catch (ex: any) {
			enqueueSnackbar("An error occurred while saving", {
				variant: "error"
			});
			console.error(ex);
			setDisabled(false);
			if (onClose) {
				onClose();
			}
		}

		if (onSave) {
			onSave(appId);
		}
	}, [isValid, appDetails, onSave, enqueueSnackbar, hcmsEnv, onClose]);

	const onCloseDialog = useCallback(() => {
		if (onClose) {
			onClose();
		}
	}, [onClose]);

	const onExitedDialog = useCallback(() => {
		setAppDetails({
			hmcsEnvironment: "",
			name: "",
			org: "",
			hcmsToken: ""
		});

		setDisabled(false);
	}, []);

	const onAppNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setAppDetails(prevState => ({
			...prevState,
			name: e.target.value
		}));
	}, []);

	const errorMessage = (
		validation: string | any,
		errorMessages: { [key: string]: string } | any,
		defaultMessage: string | any
	): string | any => {
		const customMessage = errorMessages ? errorMessages[validation] : undefined;
		return customMessage || defaultMessage;
	};

	const isNullOrWhiteSpace = (value: any) => {
		return (
			value === null ||
			value === undefined ||
			(typeof value !== "object" && (!value || value.toString().trim().length === 0))
		);
	};

	const regExpValidation = (value: any, props: any): Promise<any> => {
		const regExpPattern = /[^a-z0-9-]/;
		const beyondRange = value && value.length > 40;
		const result = {
			error: value && !isNullOrWhiteSpace(regExpPattern) ? RegExp(regExpPattern).test(value) || beyondRange : false,
			message: errorMessage(
				"regExpPattern",
				props.errorMessages,
				"This field did not match the regular expression provided."
			),
			validationName: "regExpPattern"
		};
		result.error ? setIsValid(false) : setIsValid(true);

		return result.error ? Promise.reject(result) : Promise.resolve(result);
	};

	return (
		<Dialog
			open={isOpen}
			title={`New HCMS ${appDetails.hmcsEnvironment.toUpperCase()} App`}
			onClose={onCloseDialog}
			onBackdropClick={onCloseDialog}
			onExited={onExitedDialog}
		>
			<Grid item xs={12}>
				<EnhancedAutocomplete
					id="new-app-org"
					label="Organization"
					required={true}
					value={
						selectedOrganization
							? { label: selectedOrganization.label, value: selectedOrganization.value }
							: appDetails.org
					}
					onChange={onOrgChange}
					options={orgList}
					isInDialog={true}
					inputProps={{ "data-testid": "appOrg" }}
					disabled={organization}
				/>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<EnhancedTextInput
						id="new-hcms-app"
						label="App Name"
						value={appDetails.name}
						onChange={onAppNameChange}
						fullWidth={true}
						required={true}
						helperText={
							"The Application Name cannot be longer than 40 characters and must contain only lower case letters (a-z), numbers, and dashes since it becomes part of the api url (i.e. https://content.civicplus.com/api/content/my-app/docs). You will be unable to change this later. "
						}
						errorMessages={{
							regExpPattern:
								"Application Name must contain only lower case letters (a-z), numbers, and dashes and must not be longer than 40 characters."
						}}
						validations={[regExpValidation]}
						inputProps={{ "data-testid": "appName" }}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={2} justify="flex-end" className={classes.btnsContainer}>
				<ButtonGroup>
					<Button id="save-app" color="primary" onClick={onSaveDialog} disabled={disabled}>
						Save
					</Button>
					<Button onClick={onCloseDialog}>Cancel</Button>
				</ButtonGroup>
			</Grid>
		</Dialog>
	);
};
