import Button from "@civicplus/preamble-ui/lib/Button";
import InputLabel from "@civicplus/preamble-ui/lib/InputLabel";
import List from "@civicplus/preamble-ui/lib/List";
import Tooltip from "@material-ui/core/Tooltip";
import Pending from "@material-ui/icons/AccessTime";
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import Rejected from "@material-ui/icons/Error";
import { useCallback } from "react";
import CertificateRequestConstants from "../../../constants/certificateRequestConstants";

export const CertificateRequests = ({ certificateRequests, onDelete }) => {
	const getCertificateRequestMenuItems = useCallback(() => {
		if (!certificateRequests) {
			return [];
		}
		return certificateRequests
			.filter(x => !x.isDeleted)
			.map(certificateRequest => {
				const { status, certificateAuthority, modifiedOn, id } = certificateRequest;
				let primary = "None";
				switch (certificateAuthority) {
					case CertificateRequestConstants.LETS_ENCRYPT:
						primary = "Let's Encrypt";
						break;
					case CertificateRequestConstants.SSL_STORE:
						primary = "The SSL Store";
						break;
					default:
						break;
				}
				const secondary = modifiedOn != null ? modifiedOn : "";
				const ItemAvatar = status === 2 ? <Done /> : status === 1 ? <Rejected /> : <Pending />;
				const tooltip = status === 2 ? "Done" : status === 1 ? "Rejected" : "Pending";
				const item = {
					itemAvatar: <Tooltip title={tooltip}>{ItemAvatar}</Tooltip>,
					itemText: { primary, secondary }
				};

				if (status === undefined) {
					item.itemSecondaryAction = (
						<Button
							type="icon"
							size="small"
							onClick={() => {
								onDelete(id);
							}}
						>
							<Delete />
						</Button>
					);
				}

				return item;
			});
	}, [certificateRequests, onDelete]);

	const certificateRequestOptions = getCertificateRequestMenuItems();
	if (certificateRequestOptions.length === 0) {
		return null;
	}

	return (
		<>
			<InputLabel>Certificate Requests</InputLabel>
			<List id="domain-certificateRequests" listItems={certificateRequestOptions} />
		</>
	);
};
