import { executeRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";

export const getIdentityServerConfig = async () => {
	const endpoint = {
		method: "GET",
		path: `${getApiUrl()}/api/config/account`
	};
	const response = await executeRequestAsyncFor(endpoint);
	return response.data;
};
