import enhanceWithValidation, {
	validDateValidation,
	minValueDateValidation,
	maxValueDateValidation,
} from "@civicplus/preamble-ui/lib/Validations";
import DateTimePicker from "@civicplus/preamble-ui/lib/DateTimePicker";
import { useState } from "react";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { Button } from "@civicplus/preamble-ui/lib/Button";
import { overrideCertificateExpirationAsync } from "api/certificate-management";
import { useSnackbar } from "notistack";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";

const EnhancedDatePicker = enhanceWithValidation(DateTimePicker);

interface SetExpirationDateDialogProps {
	currentExpirationDate: Date,
	certificateId: string,
	onSetCallback: () => any
}

export const OverrideExpirationButton: React.FC<SetExpirationDateDialogProps> = ({ currentExpirationDate, certificateId, onSetCallback }: SetExpirationDateDialogProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const [value, setValue] = useState(currentExpirationDate);
	const [open, setOpen] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const onChange = (event: any, value: Date) => {
		setValue(value);
	};
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const setExpirationDateCallback = async () => {
		setIsFetching(true);
		try {
			await overrideCertificateExpirationAsync({ expirationDate: value, certificateIds: [certificateId] });
		} catch (ex: any) {
			console.error(ex);
			if (ex.message !== "Network Error") {
				// Ignore request timeout error
				const defaultMessage = "Something went wrong when running the request.";
				enqueueSnackbar(getErrorMessageFromResponse(ex, ex.message || defaultMessage), {
					variant: "error"
				});
			}
		}
		onSetCallback();
		handleClose();
		setIsFetching(false);
	};
	return (
		<>
			<Button onClick={handleOpen} size="small">Override Expiration</Button>
			<Dialog
				onClose={handleClose}
				open={open}
				actions={[
					<Button disabled={isFetching} color="primary" onClick={setExpirationDateCallback} key="discard">
						Override
					</Button>,
					<Button disabled={isFetching} onClick={handleClose} key="cancel">
						Cancel
					</Button>
				]}
				title="Override Expiration Date on Certificate"
			>
				<div>This is an advanced feature. Please do not override this without understanding the implications.</div>
				<EnhancedDatePicker
					id="select-expiration=date"
					disablePast={true}
					onChange={onChange}
					value={value}
					validations={[validDateValidation, minValueDateValidation, maxValueDateValidation]}
				/>
			</Dialog>
		</>

	);
};


