import Page from "@civicplus/preamble-ui/lib/Page";
import OrgService from "@civicplus/preamble-ui/lib/Services/OrgService/OrgService";
import { getClaims } from "api/claimsApi";
import { fetchHcmsEnvironments } from "api/hcmsAppApi";
import ClaimsConstants from "constants/claimsConstants";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getApiUrl } from "utilities/environmentUtilities";
import userManager from "./api/userManager";
import HttpsIcon from '@material-ui/icons/Https';
import PublicIcon from '@material-ui/icons/Public';
import StorageIcon from '@material-ui/icons/Storage';
import BuildIcon from '@material-ui/icons/Build';
import ScheduleIcon from '@material-ui/icons/Schedule';

export const PageWrapperInternal: React.FunctionComponent<any> = props => {
	const realmsApiUrl = getApiUrl();
	const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
	const [signoutUrl, setSignoutUrl] = useState("");
	const [identityServerService, setIdentityServerService] = useState(undefined);
	const [claims, setClaims] = useState([""]);
	const [hcmsEnvironments, setHcmsEnvironments] = useState([""]);

	useEffect(() => {
		const initAsync = async () => {
			const envs = await fetchHcmsEnvironments();
			setHcmsEnvironments(envs.data);

			await userManager.initAsync();
			const authResult = await userManager.isAuthenticatedAsync();

			if (authResult) {
				const user = await userManager.getUserAsync();
				const signoutUrl = userManager.getSignoutUrl(user);
				setSignoutUrl(signoutUrl);

				const service = new OrgService({
					endpoint: userManager.getIdentityServerBaseUrl(),
					accessToken: user.access_token,
					idToken: user.id_token
				});
				setIdentityServerService(service as any);
			}

			setUserIsAuthenticated(authResult);

			const claimsResult = await getClaims();
			setClaims(claimsResult as any);
		};
		initAsync();
	}, []);

	function buildNavigation(this: any): { [section: string]: any } {
		const navSections: any = {
			Content: {
				title: "Product Management",
				items: {
					SiteManagement: {
						title: "Sites and Services",
						href: "/engage6/sites",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					}
				}
			}
		};
		const hcmsOptions: any = {};
		hcmsEnvironments.forEach((e: string) => {
			switch (e) {
				case "qa":
					hcmsOptions["ContentQA"] = {
						title: "Content-QA",
						href: "/hcms/content-qa",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					};
					break;

				case "review":
					hcmsOptions["ContentReview"] = {
						title: "Content-Review",
						href: "/hcms/content-review",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					};
					break;

				case "staging":
					hcmsOptions["ContentStaging"] = {
						title: "Content-Staging",
						href: "/hcms/content-staging",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					};

					break;

				case "sandbox":
					hcmsOptions["ContentSandbox"] = {
						title: "Content-Sandbox",
						href: "/hcms/content-sandbox",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					};

					break;
				case "production":
					hcmsOptions["Live"] = {
						title: "Content (Live/Production)",
						href: "/hcms/production",
						component: (props: any) => <Link {...props} to={props.href} block={false} />
					};

					break;

				default:
					break;
			}
		});

		navSections.HcmsContent = { title: "HCMS Management", items: hcmsOptions };

		if (claims && claims.includes(ClaimsConstants.HAS_ACCESS_TO_CLUSTERS)) {
			navSections.resourceManagment = {
				title: "Resource Management",
				items: {
					Certificates: {
						title: "Certificates",
						href: "/engage6/resources/certificates",
						component: (props: any) => <Link {...props} to={props.href} ><HttpsIcon style={{ marginRight: '5px', padding: '2px' }} /> {props.children}</Link>
					},
					Clusters: {
						title: "Clusters",
						href: "/engage6/resources/clusters",
						component: (props: any) => <Link {...props} to={props.href} ><PublicIcon style={{ marginRight: '5px', padding: '2px' }} /> {props.children}</Link>
					},
					Databases: {
						title: "Databases",
						href: "/engage6/resources/databases",
						component: (props: any) => <Link {...props} to={props.href} ><StorageIcon style={{ marginRight: '5px', padding: '2px' }} /> {props.children}</Link>
					}
				}
			};

			navSections.advanced = {
				title: "Advanced",
				items: {
					ScheduledJobs: {
						title: "Hangfire Scheduled Tasks",
						href: `${realmsApiUrl}/hangfire`,
						component: (props: any) => (
								<a {...props} href={props.href} target="_blank" rel="noopener noreferrer">
								<ScheduleIcon style={{ marginRight: '5px', padding: '2px' }} />
									<span className="cpp-MuiTypography-root cpp-MuiListItemText-primary cpp-MuiTypography-body1 cpp-MuiTypography-displayBlock">
										Scheduled Tasks
									</span>
								</a>
						)
					},
					Diagnostics: {
						title: "Diagnostics",
						href: "/engage6/diag",
						component: (props: any) => <Link {...props} to={props.href} ><BuildIcon style={{ marginRight: '5px', padding: '2px' }} /> {props.children}</Link>
					}
				}
			};
		}
		return navSections;
	}

	return (
		<Page
			disableSidebar={!userIsAuthenticated}
			disableTopbar={!userIsAuthenticated}
			disableFooter={!userIsAuthenticated}
			orgServiceSdk={identityServerService}
			id="RealmsPage"
			title="Realms"
			accountMenuOptions={
				{
					platformBaseUrl: userManager.getPlatformBaseUrl(),
					signoutUrl,
					includeDefault: true,
					show: true,
					signoutOfProduct: userManager.signOut,
					menuItems: []
				} as any
			}
			navigationSections={buildNavigation()}
		>
			{props.children({ claims })}
		</Page>
	);
};

export const PageWrapper = withRouter(PageWrapperInternal);
