import { executeRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/CertificateManagement`
	},
	listPending: {
		method: "GET",
		path: `${getApiUrl()}/api/CertificateManagement/Pending`
	},
	setAutomaticRenewDate: {
		method: "POST",
		path: `${getApiUrl()}/api/CertificateManagement/overrideexpiration`
	}
};

export const listCertificatesAsync = async (): Promise<CertificateRecord[]> => {
	const result = await executeRequestAsyncFor(endpoints.list);
	return result.data;
};

export const listPendingCertificatesAsync = async (): Promise<CertificatePendingRecord[]> => {
	const result = await executeRequestAsyncFor(endpoints.listPending);
	return result.data;
};

export const overrideCertificateExpirationAsync = (certificateExpirationOverrideRequest: CertificateExpirationOverride): Promise<void> => {
	return executeRequestAsyncFor(endpoints.setAutomaticRenewDate, certificateExpirationOverrideRequest);
};

interface CertificateExpirationOverride {
	certificateIds: string[],
	expirationDate: Date
}

export interface CertificateRecord {
	id: string,
	issuer:string,
	certificateThumbprint: string,
	domainName: string,
	siteId: string,
	isActive: boolean,
	expirationDate: string,
	parsedExpirationDate: Date,
	expirationDateEpoch: number
}

export interface CertificatePendingRecord {
	id: string,
	createdOn: string,
	modifiedOn: string,
	domainName: string,
	siteId: string,
	status: string
}

