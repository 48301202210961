import Autocomplete from "@civicplus/preamble-ui/lib/Autocomplete";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import enhanceWithValidation from "@civicplus/preamble-ui/lib/Validations";
import { makeStyles } from "@material-ui/core/styles";
import { fetchAllOrganizations, fetchHcmsTokenAsync, modifyHcmsApp, modifyOrgServiceApp } from "api/hcmsAppApi";
import { HcmsApp } from "components/HcmsApps/HcmsApp";
import { Organization } from "components/HcmsApps/Organization";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";

const useStyles = makeStyles(() => ({
	gridTextWrapper: {
		display: "grid",
		alignItems: "center",
		paddingRight: "0px !important"
	},
	gridIconWrapper: {
		paddingRight: "35px !important"
	},
	btnsContainer: {
		marginTop: 20
	}
}));

const EnhancedAutocomplete = enhanceWithValidation(Autocomplete);
const EnhancedTextInput = enhanceWithValidation(TextInput);

interface ModifyAppModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: () => void;
	app: HcmsApp | undefined;
	hcmsEnv: string;
}
export interface AppDetails {
	name: string;
	org: { label: string; value: string };
	hmcsEnvironment: string;
	hcmsToken: string;
}

export const ModifyAppModal: React.FC<ModifyAppModalProps> = ({ isOpen, onClose, onSave, app, hcmsEnv }) => {
	const classes = useStyles();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [orgList, setOrgList] = useState<any[]>([]);
	const [isValid, setIsValid] = useState<boolean>(true);
	const { enqueueSnackbar } = useSnackbar();

	const [appDetails, setAppDetails] = useState<AppDetails>({
		name: app?.name || "",
		org: app?.organization ? { label: app?.organization, value: app?.organization } : { label: "", value: "" },
		hmcsEnvironment: hcmsEnv,
		hcmsToken: ""
	});
	const oldOrgId = app?.organization;

	useEffect(() => {
		const initAsync = async () => {
			const orgOptions: any = [];
			const allOrgs = (await fetchAllOrganizations()).data;

			allOrgs.forEach((o: Organization) => {
				orgOptions.push({ label: o.name, value: o.id });
			});
			setOrgList(orgOptions);

			const orgName = app?.organization?.split(",")?.[1];
			if (orgName) {
				const orgField = { label: orgName, value: orgName };
				setAppDetails({ name: app?.name || "", org: orgField, hmcsEnvironment: hcmsEnv, hcmsToken: "" });
			} else {
				setAppDetails({
					name: app?.name || "",
					org: { label: "", value: "" },
					hmcsEnvironment: hcmsEnv,
					hcmsToken: ""
				});
			}
			return allOrgs;
		};
		initAsync();
	}, [isOpen, app, hcmsEnv]);

	const onSaveDialog = useCallback(async () => {
		setDisabled(true);
		if (!isValid) {
			enqueueSnackbar(`Please provide a valid application name.`, {
				variant: "error"
			});
			setDisabled(false);
			return;
		}
		const localHcmsToken = (await fetchHcmsTokenAsync(appDetails.hmcsEnvironment)).data;
		appDetails.hcmsToken = localHcmsToken;

		try {
			const oldOrg = oldOrgId && oldOrgId.split(",")?.[0];

			const orgName = appDetails.org.label;
			const orgId = appDetails.org.value;
			const namedOrgId = `${orgName},${orgId}`;

			const updateAppRequest: any = appDetails;
			updateAppRequest.org = namedOrgId;

			const organizationApp = await modifyOrgServiceApp(
				oldOrg,
				orgId,
				updateAppRequest.name,
				appDetails.hmcsEnvironment
			);
			if (organizationApp?.data?.organizationId == orgId) {
				await modifyHcmsApp(updateAppRequest);
			}

			enqueueSnackbar(`"${appDetails.name}" has been modified.`, {
				variant: "success"
			});
		} catch (ex: any) {
			enqueueSnackbar(getErrorMessageFromResponse(ex, "An error occurred while saving"), {
				variant: "error"
			});
			console.error(ex);
			setDisabled(false);
		}

		if (onSave) {
			onSave();
		}
		setAppDetails({
			hmcsEnvironment: "",
			name: "",
			org: { label: "", value: "" },
			hcmsToken: ""
		});
	}, [isValid, appDetails, onSave, enqueueSnackbar, oldOrgId]);

	const onCloseDialog = useCallback(() => {
		if (onClose) {
			setAppDetails({
				hmcsEnvironment: "",
				name: "",
				org: { label: "", value: "" },
				hcmsToken: ""
			});
			onClose();
		}
	}, [onClose]);

	const onExitedDialog = useCallback(() => {
		setAppDetails({
			hmcsEnvironment: "",
			name: "",
			org: { label: "", value: "" },
			hcmsToken: ""
		});

		setDisabled(false);
	}, []);

	const onOrgChange = useCallback((selected: { label: string; value: string }) => {
		setAppDetails(prevState => ({
			...prevState,
			org: { label: selected.label, value: selected.value }
		}));
	}, []);

	const errorMessage = (
		validation: string | any,
		errorMessages: { [key: string]: string } | any,
		defaultMessage: string | any
	): string | any => {
		const customMessage = errorMessages ? errorMessages[validation] : undefined;
		return customMessage || defaultMessage;
	};

	const isNullOrWhiteSpace = (value: any) => {
		return (
			value === null ||
			value === undefined ||
			(typeof value !== "object" && (!value || value.toString().trim().length === 0))
		);
	};

	const regExpValidation = (value: any, props: any): Promise<any> => {
		const regExpPattern = /[^a-z0-9-]/;
		const beyondRange = value && value.length > 40;
		const result = {
			error: value && !isNullOrWhiteSpace(regExpPattern) ? RegExp(regExpPattern).test(value) || beyondRange : false,
			message: errorMessage(
				"regExpPattern",
				props.errorMessages,
				"This field did not match the regular expression provided."
			),
			validationName: "regExpPattern"
		};
		result.error ? setIsValid(false) : setIsValid(true);

		return result.error ? Promise.reject(result) : Promise.resolve(result);
	};

	return (
		<Dialog
			open={isOpen}
			title={`Modify HCMS ${appDetails.hmcsEnvironment.toUpperCase()} App`}
			onClose={onCloseDialog}
			onBackdropClick={onCloseDialog}
			onExited={onExitedDialog}
		>
			<Grid item xs={12}>
				<EnhancedAutocomplete
					id="hcms-app-org"
					label="Organization"
					required={true}
					value={appDetails.org}
					onChange={onOrgChange}
					options={orgList}
					isInDialog={true}
					inputProps={{ "data-testid": "appOrg" }}
				/>
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<EnhancedTextInput
						id="hcms-app-name"
						label="App Name"
						value={app?.name}
						disabled={true}
						fullWidth={true}
						required={true}
						helperText={
							"The Application Name cannot be longer than 40 characters and must contain only lower case letters (a-z), numbers, and dashes since it becomes part of the api url (i.e. https://content.civicplus.com/api/content/my-app/docs). You will be unable to change this later. "
						}
						errorMessages={{
							regExpPattern:
								"Application Name must contain only lower case letters (a-z), numbers, and dashes and must not be longer than 40 characters."
						}}
						validations={[regExpValidation]}
						inputProps={{ "data-testid": "appName" }}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={2} justify="flex-end" className={classes.btnsContainer}>
				<ButtonGroup>
					<Button color="primary" onClick={onSaveDialog} disabled={disabled}>
						Save
					</Button>
					<Button onClick={onCloseDialog}>Cancel</Button>
				</ButtonGroup>
			</Grid>
		</Dialog>
	);
};
