import Button from "@civicplus/preamble-ui/lib/Button";
import FileUpload from "@civicplus/preamble-ui/lib/FileUpload";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useCallback, useState } from "react";
import CertificateRequestConstants from "../../../constants/certificateRequestConstants";

const CertificateForm = ({
	onCertificateAdded,
	onCertificateRequestAdded,
	hasLetsEncryptCertificate
}) => {
	const [formValues, setFormValues] = useState({});

	const onPasswordChange = useCallback(
		e => {
			formValues.pfxPassword = e.target.value;
			setFormValues({ ...formValues });
		},
		[formValues]
	);

	const onDrop = useCallback(
		fileArray => {
			formValues.file = fileArray[0];
			setFormValues({ ...formValues });
		},
		[formValues]
	);

	const onCertificateAdd = useCallback(() => {
		onCertificateAdded && onCertificateAdded(formValues);
		setFormValues({});
	}, [formValues, onCertificateAdded]);

	const onLetsEncryptCertificate = useCallback(() => {
		onCertificateRequestAdded && onCertificateRequestAdded(formValues, CertificateRequestConstants.LETS_ENCRYPT);
	}, [formValues, onCertificateRequestAdded]);

	const onSslStoreCertificate = useCallback(() => {
		onCertificateRequestAdded && onCertificateRequestAdded(formValues, CertificateRequestConstants.SSL_STORE);
	}, [formValues, onCertificateRequestAdded]);

	return (
		<Grid container spacing={24}>
			<Grid item xs={2}>
				<Button id="domain-letsEncrypt" disabled={hasLetsEncryptCertificate} onClick={onLetsEncryptCertificate}>
					Let&apos;s Encrypt
				</Button>
				<Button id="domain-sslStore" disabled onClick={onSslStoreCertificate} title="Disabled due to problems. Use Let's Encrypt Instead. It now supports automatic certificate renewal.">
					The SSL Store
				</Button>
			</Grid>
			<Grid item xs={9}>
				<Grid item xs>
					<FileUpload
						id="domain-pfxFile"
						required
						filesUploaded={formValues.file ? [formValues.file] : []}
						onDrop={onDrop}
						multiple={false}
						fileTypes=".pfx"
					>
						Click or drag to add PFX file
					</FileUpload>
				</Grid>
				<Grid item xs>
					<TextInput
						autoComplete="new-password"
						id="domain-pfxPassword"
						helperText="Password used for the PFX file"
						label="Password"
						value={formValues.pfxPassword || ""}
						onChange={onPasswordChange}
						fullWidth
						variant="filled"
						type="password"
					/>
				</Grid>
			</Grid>
			<Grid item xs={1}>
				<Button
					id="domain-addCertificate"
					disabled={!formValues.file}
					color="primary"
					onClick={onCertificateAdd}
					style={{ height: "100%" }}
				>
					<AddCircleOutlineIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

export default CertificateForm;
