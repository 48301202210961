import Breadcrumbs from "@civicplus/preamble-ui/lib/Breadcrumbs/Breadcrumbs";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Checkbox from "@civicplus/preamble-ui/lib/Checkbox";
import CodeEditor from "@civicplus/preamble-ui/lib/CodeEditor";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import { QueryRequest, executeQueryOnDbsAsync } from "api/database-management";
import Loader from "components/loader/Loader";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { getDatabaseServerName } from "../../../utilities/environmentUtilities";

export const QueryDbs: React.FC = () => {
	const [queryRequest, setQueryRequest] = useState<QueryRequest>({
		query: undefined,
		parameters: {}
	});
	const [includePrecreatedDbs, setIncludePrecreatedDbs] = useState<boolean>(false);
	const [isQuerying, setIsQuerying] = useState<boolean>(false);
	const [queryResponse, setQueryResponse] = useState<string>();
	const { enqueueSnackbar } = useSnackbar();

	const queryAsync = useCallback(async () => {
		setIsQuerying(true);

		try {
			const defaultDatabaseServer = getDatabaseServerName();
			const result = await executeQueryOnDbsAsync(queryRequest, defaultDatabaseServer, includePrecreatedDbs);
			setQueryResponse(JSON.stringify(result, null, 2));
		} catch (ex: any) {
			console.error(ex);
			const defaultMessage = "Something went wrong when querying databases.";
			enqueueSnackbar(getErrorMessageFromResponse(ex, defaultMessage), {
				variant: "error"
			});
		}

		setIsQuerying(false);
	}, [queryRequest, includePrecreatedDbs, enqueueSnackbar]);

	const onChangeWrapper = {
		onChange: (e: any, value: string) => {
			setQueryRequest(prevState => ({
				...prevState,
				query: value
			}));
		}
	};
	return (
		<>
			<Breadcrumbs id="manageSiteForm-breadcrumbs">
				<Link to="/engage6/resources/databases">Databases</Link>
			</Breadcrumbs>

			<CodeEditor
				id="query"
				label="SQL Query"
				language="sql"
				height={100}
				value={queryRequest.query}
				{...onChangeWrapper}
			/>
			<Grid container spacing={1}>
				<Grid item>
					<Checkbox
						id="query-include-precreated"
						label="Include Precreated DBs"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setIncludePrecreatedDbs(e.target.checked);
						}}
					/>
				</Grid>
				<Grid item>
					<ButtonGroup layout="left">
						<Button
							id="query-execute"
							size="medium"
							color="primary"
							disabled={isQuerying}
							onClick={async () => {
								setQueryResponse("");
								queryAsync();
							}}
						>
							{isQuerying ? <span>Querying...</span> : "Execute"}
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			{isQuerying && <Loader variant="linear" />}
			{queryResponse && (
				<CodeEditor
					id="query-response"
					label="Results"
					language="json"
					height={600}
					value={queryResponse}
					readOnly={true}
				/>
			)}
		</>
	);
};
