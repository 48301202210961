import { UserManager as OidcUserManager } from "oidc-client";
import { getIdentityServerConfig } from "./configuration";

class UserManagerClass {
	_manager;
	_platformBaseUrl = "";
	_identityServerBaseUrl = "";
	_postLogoutRedirectUri = "";
	_authority = "";
	_userCached;

	get manager() {
		return this._manager;
	}

	isInitialized() {
		return this._manager != null;
	}

	initAsync = async () => {
		if (!this._manager) {
			const data = await getIdentityServerConfig();

			const config = {
				authority: `${data.authority}`,
				client_id: `${data.clientId}`,
				redirect_uri: `${data.postLoginRedirectUri}`,
				response_type: `${data.responseType}`,
				scope: `${data.scope}`,
				post_logout_redirect_uri: `${data.postLogoutRedirectUri}`,
				automaticSilentRenew: true,
				filterProtocolClaims: true,
				loadUserInfo: true,
				response_mode: "query"
			};
			this._platformBaseUrl = data.platformBaseUrl;
			this._identityServerBaseUrl = data.identityServerBaseUrl;
			this._authority = data.authority;
			this._postLogoutRedirectUri = data.postLogoutRedirectUri;
			this._manager = new OidcUserManager(config);

			this._manager.events.addUserSignedOut(() => {
				this._manager.removeUser();
			});
		}
	};

	signInRedirectCallbackAsync = async () => {
		await this._manager.signinRedirectCallback();

		let redirect = "/404";

		if (await this.isAuthenticatedAsync()) {
			redirect = window.sessionStorage.getItem("redirect_uri") || "/";
		}
		window.location.assign(redirect);
	};

	signIn = from => {
		window.sessionStorage.setItem("redirect_uri", from);
		return this._manager.signinRedirect();
	};

	signOut = () => {
		return this._manager.signoutRedirect();
	};

	isAuthenticatedAsync = async () => {
		const user = await this.getUserAsync();

		const authenticated = user && !user.expired;

		return authenticated;
	};

	getPlatformBaseUrl = () => this._platformBaseUrl;
	getIdentityServerBaseUrl = () => this._identityServerBaseUrl;
	getAuthority = () => this._authority;
	getPostLogoutRedirectUrl = () => this._postLogoutRedirectUri;

	getSignoutUrl = user => {
		return `${this.getAuthority()}/connect/endsession?post_logout_redirect_uri=${this.getPostLogoutRedirectUrl()}&id_token_hint=${
			user.id_token
		}`;
	};

	getUserAsync = async () => {
		if (this._userCached) {
			return this._userCached;
		}
		if (!this.isInitialized()) {
			return null;
		}
		const user = await this._manager.getUser();
		if (!user || user.expired) {
			return null;
		}
		this._userCached = {
			token_type: user.token_type,
			id_token: user.id_token,
			scope: user.scope,
			profile: { email: (user.profile || {}).email },
			access_token: user.access_token,
			expires_at: user.expires_at
		};
		return this._userCached;
	};
}

const userManager = new UserManagerClass();
export default userManager;
