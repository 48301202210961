import { useCallback, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import userManager from "../../api/userManager";

export const RestrictedRoute = props => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasCheckedAutentication, setHasCheckedAutentication] = useState(false);

	const validateAuth = useCallback(async () => {
		const authResult = await userManager.isAuthenticatedAsync();
		if (!hasCheckedAutentication) {
			setIsAuthenticated(authResult);
			setHasCheckedAutentication(true);
		}
	}, [hasCheckedAutentication]);

	useEffect(() => {
		validateAuth();
	}, [isAuthenticated, validateAuth]);

	if (!hasCheckedAutentication) {
		return <div>Authenticating...</div>;
	}

	if (!isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: "/CivicPlusPlatform/SignIn",
					state: { from: props.location }
				}}
			/>
		);
	}

	return <Route {...props} />;
};
