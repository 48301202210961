import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import Button from "@civicplus/preamble-ui/lib/Button";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import { deleteClusterAsync, getClustersAsync } from "api/clustersApi";
import { ConfirmationModal, ConfirmationModalProps } from "components/modal/ConfirmationModal";
import memoize from "memoize-one";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getIsAddEnvEnabled } from "utilities/environmentUtilities";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import Loader from "../../loader/Loader";
import { Cluster } from "./Types/Cluster";
import { getRegionKeyFromValue } from "./Types/Region";

const columns = [
	{
		name: "Cluster Name",
		label: "Cluster Name",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "Description",
		label: "Description",
		options: {
			filter: true,
			sort: true,
			display: false
		}
	},
	{
		name: "Region",
		label: "Region",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "IP Address",
		label: "IP Address",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "Link",
		label: "Link",
		options: {
			filter: true,
			sort: true
		}
	},
	{
		name: "",
		label: "",
		options: {
			filter: false,
			sort: false,
			viewColumns: false
		}
	}
];

const deleteModalDefaultTitle = "Delete Cluster";
const deleteModalDefaultContent = "Are you sure you want to delete this cluster? This action cannot be undone.";

export const Clusters: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [clusters, setClusters] = useState<Cluster[]>([]);
	const [deleteModalProps, setDeleteModalProps] = useState<ConfirmationModalProps>({
		open: false,
		title: "Delete Cluster",
		content: "Are you sure you want to delete this cluster? This action cannot be undone."
	});
	const [clusterIdToDelete, setClusterIdToDelete] = useState<string>();
	const { enqueueSnackbar } = useSnackbar();

	const resetDeleteModal = () => {
		setDeleteModalProps({
			open: false,
			content: deleteModalDefaultContent,
			title: deleteModalDefaultTitle
		});
	};

	useEffect(() => {
		async function fetchData() {
			try {
				const clusters = await getClustersAsync();
				setClusters(clusters);
				setIsLoading(false);
			} catch (ex: any) {
				if (ex && ex.response && ex.response.status === 403) {
					window.location.assign("/403");
					return;
				}
			}
		}
		fetchData();
	}, []);

	const onDeleteButtonClick = (envrionmentId: string) => () => {
		const env = clusters.find(x => x.id === envrionmentId);
		let content = deleteModalProps.content;
		let title = deleteModalProps.title;
		if (env) {
			title = `Delete ${env.name}`;
			content = `Are you sure you want to delete ${env.name}? This action cannot be undone.`;
		}

		setDeleteModalProps(prevState => ({
			...prevState,
			open: true,
			title,
			content
		}));
		setClusterIdToDelete(env?.id);
	};

	const clustersForAdvancedTable = memoize(clusters => {
		return clusters.map((cluster: Cluster) => {
			const clusterName = cluster.name;
			const description = cluster.description;
			const region = getRegionKeyFromValue(cluster.region);
			const ipAddress = cluster.ipAddress;
			const link = cluster.link;
			return [
				clusterName,
				description,
				region,
				ipAddress,
				<a key={cluster.link} target="_blank" href={link} rel="noopener noreferrer">
					<OpenInBrowserIcon style={{ color: "#000", marginLeft: "3px", verticalAlign: "middle" }} />
				</a>,
				<Button key={cluster.id} disabled={cluster.name === "azuredefault"} onClick={onDeleteButtonClick(cluster.id)}>
					Delete
				</Button>
			];
		});
	});

	const options = {
		pagination: false,
		responsive: "vertical",
		selectableRows: "none"
	};

	const onCloseDeleteModal = () => {
		resetDeleteModal();
	};

	const onConfirmDeleteModal = async () => {
		if (clusterIdToDelete) {
			try {
				await deleteClusterAsync(clusterIdToDelete);
				const env = clusters.find(x => x.id === clusterIdToDelete);
				enqueueSnackbar(`Successfully deleted ${env ? env.name : "the cluster"}.`, {
					variant: "success"
				});
				const newClusterList = clusters.filter(x => x.id !== clusterIdToDelete);
				setClusters(newClusterList);
			} catch (ex: any) {
				enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to delete cluster."), {
					variant: "error"
				});
			}
		}

		resetDeleteModal();
	};

	if (isLoading) {
		return <Loader>Loading...</Loader>;
	}

	return (
		<>
			{getIsAddEnvEnabled() && (
				<div>
					<Link to="/engage6/resources/clusters/new">
						<Button id="cluster-add">ADD CLUSTER</Button>
					</Link>
				</div>
			)}
			<AdvancedTable title="Clusters" columns={columns} data={clustersForAdvancedTable(clusters)} options={options} />
			<ConfirmationModal
				{...deleteModalProps}
				onCancel={onCloseDeleteModal}
				onClose={onCloseDeleteModal}
				onConfirm={onConfirmDeleteModal}
				fullHeight={false}
			/>
		</>
	);
};
