import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import memoize from "memoize-one";
import { useEffect, useState } from "react";
import Loader from "../../loader/Loader";
import { CertificatePendingRecord, listPendingCertificatesAsync } from "api/certificate-management";
import { formatDistance } from "date-fns";
import Badge from "@material-ui/core/Badge";
import Fade from "@material-ui/core/Fade";
import ExpansionPanel from "@civicplus/preamble-ui/lib/ExpansionPanel";
import UpdateIcon from '@material-ui/icons/Update';
import { Button } from "@civicplus/preamble-ui/lib/Button";
import RefreshIcon from '@material-ui/icons/Refresh';

const options = {
    pagination: true,
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    sortOrder: { name: 'Created On', direction: 'descending' },
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
};

const certificatesForAdvancedTable = memoize((certificates: CertificatePendingRecord[]) => {
    return certificates.map(certificate => {
        return [
            certificate.id,
            certificate.siteId,
            certificate.domainName,
            certificate.createdOn,
            certificate.modifiedOn
        ];
    });
});

export const CertificateRequestsListing: React.FC = () => {
    const [certificates, setCertificates] = useState<CertificatePendingRecord[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const fetchDataAsync = async () => {
        try {
            setIsUpdating(true);
            const result = await listPendingCertificatesAsync();
            setCertificates(result);
            setIsLoading(false);
            setIsUpdating(false);
        } catch (ex: any) {
            if (ex && ex.response && ex.response.status === 403) {
                window.location.assign("/403");
                return;
            }
        }
    }
    useEffect(() => {
        fetchDataAsync();
    }, [setCertificates]); // Or [] if effect doesn't need props or state

    const renderDate = (value: any[], tableData: any) => {
        try {
            const rawExpirationDate = tableData.rowData[tableData.columnIndex];
            const expirationDate = new Date(rawExpirationDate);
            if (!rawExpirationDate || !expirationDate) {
                return;
            }
            const differenceInTime = expirationDate.getTime() - new Date().getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            const isWithinRenewalPeriod = differenceInDays < 30 && differenceInDays > 0;
            const isExpired = differenceInDays < 0;
            const expirationDateStyles = isExpired
                ? { color: '#CCC' }
                : isWithinRenewalPeriod
                    ? { color: 'orange', fontWeight: 'bold' } :
                    { color: 'green' };
            const expirationDateText = rawExpirationDate ? formatDistance(expirationDate, new Date(), { addSuffix: true }) : "";
            return <span style={expirationDateStyles} title={rawExpirationDate ? expirationDate.toLocaleDateString() : "Unknown"}>{expirationDateText}</span>;
        } catch (ex) {
            console.error(ex);
            return "Error parsing expiration time."
        }
    };

    const columns = [
        {
            name: "Id",
            label: "Id",
            flex: 1,
            options: {
                filter: true,
                sort: false,
                display: true
            }
        },
        {
            name: "Site Id",
            label: "Site Id",
            flex: 1,
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "Domain",
            label: "Domain",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Created On",
            label: "Created On",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: true
            }
        },
        {
            name: "Updated On",
            label: " Updated On",
            flex: 1,
            options: {
                filter: true,
                sort: true,
                display: true,
                customBodyRender: renderDate
            }
        }
    ];

    return (
        <>
            {isLoading ? (
                <Loader>Loading...</Loader>
            ) : (
                <Fade in>
            <ExpansionPanel startIcon={<Badge color="primary" overlap="rectangle" badgeContent={certificates.length}><UpdateIcon /></Badge>}
            expandActions={<Button disabled={isUpdating} startIcon={<RefreshIcon/>} title="Refresh"
            onClick={fetchDataAsync}>
                Refresh
        </Button>}
             expandSummary="Pending Certificates" expandDetails={
            <AdvancedTable
                            columns={columns}
                            compact={true}
                            data={certificatesForAdvancedTable(certificates)}
                            options={options}
                        />}/>
                </Fade>
            )}
        </>
    );
};
