import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Refresh from "@material-ui/icons/Refresh";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import CertificateRequestConstants from "../../../constants/certificateRequestConstants";
import { isDefaultDomain } from "../../../utilities/environmentUtilities";
import CertificateForm from "./CertificateForm";
import { CertificateRequests } from "./CertificateRequests";
import { Certificates } from "./Certificates";

const formatDomain = domain => domain && domain.replace("http://", "").replace("https://", "");

/* eslint-disable */
const isValid = (domain = "") =>
	/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(domain);
/* eslint-enable */

const filterCertificateById = id => x => x.id === id || (x.file && x.file.preview === id);

const AddDomain = ({ onSaveDomain, onRefreshDomain, onClose, open, data }) => {
	const [formValues, setFormValues] = useState({});
	const [errors, setErrors] = useState({});

	const onSave = useCallback(() => {
		if (!isValid(formValues.domainName)) {
			return;
		}
		const domainName = formatDomain(formValues.domainName);
		const result =
			onSaveDomain &&
			onSaveDomain({
				...formValues,
				domainName
			});
		result ? setErrors(result) : onClose();
	}, [formValues, onClose, onSaveDomain]);

	useEffect(() => {
		const certificates = data.certificates || [];
		setFormValues({ ...data, certificates: certificates.map(cert => ({ ...cert })) });
	}, [data]);

	// Set errors back to empty if we close or open the Dialog
	useEffect(() => {
		setErrors({});
	}, [open]);

	const onDeleteCertificate = useCallback(
		id => {
			const cert = formValues.certificates.find(filterCertificateById(id));
			cert.isDeleted = true;
			setFormValues({ ...formValues, certificates: [...formValues.certificates] });
		},
		[formValues]
	);

	const onDeleteCertificateRequest = useCallback(
		id => {
			const indexToDelete = formValues.certificateRequests.findIndex(x => x.id === id);

			formValues.certificateRequests.splice(indexToDelete, 1);
			setFormValues({
				...formValues
			});
		},
		[formValues]
	);

	const onCertificateIsActiveChange = useCallback(
		event => {
			// Might be a different property name to find it by here? does certification.thumbprint exist? is that what we want as the option?
			const newActiveCert = formValues.certificates.find(filterCertificateById(event.target.value));
			const oldActiveCert = formValues.certificates.find(x => x.isActive);
			if (oldActiveCert) {
				oldActiveCert.isActive = false;
			}
			newActiveCert.isActive = true;
			formValues.certificates = [...formValues.certificates];
			setFormValues({ ...formValues });
		},
		[formValues]
	);

	const onCertificateAdded = useCallback(
		certificate => {
			if (!formValues.certificates) {
				formValues.certificates = [];
			}
			const certificates = formValues.certificates.concat([{ ...certificate, isActive: false }]);
			setFormValues({ ...formValues, certificates });
		},
		[formValues]
	);

	const onCertificateRequestAdded = useCallback(
		(values, certificateAuthority) => {
			if (!formValues.certificateRequests) {
				formValues.certificateRequests = [];
			}
			const certificateRequests = formValues.certificateRequests.concat([{ certificateAuthority }]);
			setFormValues({ ...formValues, certificateRequests });
		},
		[formValues]
	);

	const hasLetsEncryptCertificate =
		formValues.certificateRequests &&
		formValues.certificateRequests.find(x => x.certificateAuthority === CertificateRequestConstants.LETS_ENCRYPT) !=
			null;

	const hasSslStoreCertificate =
		formValues.certificateRequests &&
		formValues.certificateRequests.find(x => x.certificateAuthority === CertificateRequestConstants.SSL_STORE) != null;
	const isEditing = data.id != null;

	return (
		<Dialog
			id="add-domain-dialog"
			maxWidth="md"
			fullWidth={true}
			open={open}
			onClose={onClose}
			title="Please enter a domain name"
			actions={
				<Button id="domain-save" disabled={!isValid(formValues.domainName)} color={"primary"} onClick={onSave}>
					SAVE
				</Button>
			}
		>
			<>
				{isEditing && (
					<Button
						id="domain-refresh"
						type="icon"
						size="small"
						onClick={() => {
							onRefreshDomain(data.id);
						}}
					>
						<Refresh />
					</Button>
				)}

				<TextInput
					disabled={isEditing}
					id="domain-name"
					variant={"filled"}
					autoFocus
					fullWidth={true}
					label="Domain"
					helperText="Add a domain for the site. http:// is unecessary, and will be removed if placed."
					placeholder="Domain"
					onChange={e => {
						setFormValues({ ...formValues, domainName: e.target.value });
						setErrors({});
					}}
					onKeyUp={e => {
						if (e.key === "Enter") {
							onSave();
						}
					}}
					value={formValues.domainName || ""}
					error={!!get(errors, "domainName.errorMessage")}
					errorMessage={get(errors, "domainName.errorMessage")}
				/>
				{!isDefaultDomain(formValues.domainName) && (
					<>
						<CertificateForm
							hasLetsEncryptCertificate={hasLetsEncryptCertificate}
							hasSslStoreCertificate={hasSslStoreCertificate}
							onCertificateAdded={onCertificateAdded}
							onCertificateRequestAdded={onCertificateRequestAdded}
						/>
						<Certificates
							certificates={formValues.certificates}
							onChange={onCertificateIsActiveChange}
							onDelete={onDeleteCertificate}
						/>
						<CertificateRequests
							certificateRequests={formValues.certificateRequests}
							onDelete={onDeleteCertificateRequest}
						/>
					</>
				)}
			</>
		</Dialog>
	);
};

AddDomain.propTypes = {
	onSaveDomain: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	data: PropTypes.shape({})
};

export default AddDomain;
