import { getApiUrl } from "../utilities/environmentUtilities";
import { executeRequestAsyncFor } from "./apiUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/claims`
	}
};

export const getClaims = async (): Promise<string[]> => {
	const result = await executeRequestAsyncFor(endpoints.list);
	return result.data;
};
