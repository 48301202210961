import DropDown from "@civicplus/preamble-ui/lib/DropDown";
import { getClustersAsync } from "api/clustersApi";
import { moveSiteAsync } from "api/sitesApi";
import { Cluster } from "components/Resources/Clusters/Types/Cluster";
import Loader from "components/loader/Loader";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { Site } from "./Types/Site";

export interface MoveModalProps {
	site?: Site;
	isOpen: boolean;
	onMoveSuccess?: () => void;
	onMoveFailure?: () => void;
	onMoveCancel?: () => void;
	onMoveClose?: () => void;
}

export const MoveModal: React.FC<MoveModalProps> = ({
	isOpen,
	site,
	onMoveSuccess,
	onMoveFailure,
	onMoveCancel,
	onMoveClose
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [clusters, setClusters] = useState<Cluster[]>([]);
	const [selectedClusterId, setSelectedClusterId] = useState<string>();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		async function fetchData() {
			try {
				const clusters = await getClustersAsync();
				setClusters(clusters);
				setIsLoading(false);
			} catch (ex: any) {
				if (ex && ex.response && ex.response.status === 403) {
					window.location.assign("/403");
					return;
				}
			}
		}
		fetchData();
	}, []);

	const onDropdownChange = useCallback((result: { label: string; value: string }) => {
		setSelectedClusterId(result.value);
	}, []);

	const enivronmentOptions = useMemo(() => {
		return clusters.map(x => ({ label: x.name, value: x.id }));
	}, [clusters]);

	const selectedOption = useMemo(() => {
		const id = selectedClusterId || site?.clusterId;
		return enivronmentOptions.find(x => x.value === id);
	}, [enivronmentOptions, selectedClusterId, site?.clusterId]);

	const content = useMemo(() => {
		if (isLoading) {
			return <Loader />;
		}

		return (
			<div>
				<DropDown isInDialog options={enivronmentOptions} value={selectedOption} onChange={onDropdownChange} />
				<h3>You will need to coordinate with the client to ensure DNS is directed to the Target Cluster</h3>
			</div>
		);
	}, [enivronmentOptions, isLoading, onDropdownChange, selectedOption]);

	const onConfirm = useCallback(async () => {
		if (!site) {
			throw new Error("The site could not be found when trying to change it's cluster.");
		}

		try {
			// If they haven't selected one, or its equal to the one already selected, throw an error.
			if (!selectedClusterId || selectedClusterId === site?.clusterId) {
				enqueueSnackbar("You must select a cluster to move to.", {
					variant: "error"
				});
			} else {
				await moveSiteAsync({ clusterId: selectedClusterId, siteId: site.id });
				onMoveSuccess?.();
				enqueueSnackbar("Successfully moved cluster", {
					variant: "success"
				});
			}
		} catch (ex: any) {
			setIsLoading(false);
			enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to move site cluster."), {
				variant: "error"
			});
			onMoveFailure?.();
		}
		setSelectedClusterId("");
	}, [enqueueSnackbar, onMoveFailure, onMoveSuccess, selectedClusterId, site]);

	const onCancel = () => {
		onMoveCancel?.();
		setSelectedClusterId("");
	};

	const onClose = () => {
		onMoveClose?.();
		setSelectedClusterId("");
	};

	return (
		<ConfirmationModal
			data-testid="move-modal"
			open={isOpen}
			title={`Move ${site?.name} to a Different Cluster`}
			confirmButtonText="Move Site"
			content={content}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			fullHeight={false}
		/>
	);
};
