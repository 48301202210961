import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
	<SnackbarProvider maxSnack={3}>
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>
	</SnackbarProvider>
);
