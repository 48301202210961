import { getApiUrl } from "utilities/environmentUtilities";
import { executeRequestAsyncFor } from "./apiUtilities";

const endpoints = {
	hitSitesRoute: {
		method: "POST",
		path: `${getApiUrl()}/api/diagnostics/hitsitesroute`
	},
	introspection: {
		method: "GET",
		path: `${getApiUrl()}/api/introspect`
	}
};

const throwRequestError = (error: any) => {
	if (error.response && error.response.data && error.response.data.message) {
		throw new Error(error.response.data.message);
	}
	throw error;
};

export const hitSitesRouteAsync = async (route: string): Promise<void> => {
	try {
		return await executeRequestAsyncFor(endpoints.hitSitesRoute, { route });
	} catch (error: any) {
		throwRequestError(error);
	}
};

export const introspectionAsync = async (): Promise<any> => {
	try {
		return await executeRequestAsyncFor(endpoints.introspection, {});
	} catch (error: any) {
		throwRequestError(error);
	}
};
