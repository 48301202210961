import OrgService from "@civicplus/preamble-ui/lib/Services/OrgService/OrgService";
import { PageWrapper } from "PageWrapper";
import Diagnostics from "components/Diagnostics/Diagnostics";
import { ClusterCreateAndModify } from "components/Resources/Clusters/ClusterManagement";
import { Clusters } from "components/Resources/Clusters/Clusters";
import { QueryDbs } from "components/Resources/Databases/QueryDbs";
import ClaimsConstants from "constants/claimsConstants";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import userManager from "./api/userManager";
import { HcmsAppManagement } from "./components/HcmsApps/HcmsAppManagement";
import { OperationProgressManager } from "./components/OperationProgress/OperationProgressManager";
import { Page403 } from "./components/Page403/Page403";
import { DatabaseManager } from "./components/Resources/Databases/DatabaseManager";
import { RestrictedRoute } from "./components/RestrictedRoute/RestrictedRoute";
import { SignIn } from "./components/SignIn/SignIn";
import { SignInCallback } from "./components/SignInCallback/SignInCallback";
import { SignOut } from "./components/SignOut/SignOut";
import ManageSiteForm from "./components/Sites/ManageSiteForm";
import { SiteManagement } from "./components/Sites/SiteManagement.tsx";
import { CertificatesListing } from "components/Resources/Certificates/CertificatesListing";

const App = () => {
	const [hasAuthenticationChecked, setHasAuthenticationChecked] = useState(false);

	useEffect(() => {
		const initAsync = async () => {
			await userManager.initAsync();
			const authResult = await userManager.isAuthenticatedAsync();

			if (authResult) {
				const user = await userManager.getUserAsync();
				const service = new OrgService({
					endpoint: userManager.getIdentityServerBaseUrl(),
					accessToken: user.access_token,
					idToken: user.id_token
				});
				//Do not get product list for new IDS
				service.GetClientListAsync = () => {
					/**/
				};
				service.GetUsersOrganizations = () => {
					/**/
				};
			}
			setHasAuthenticationChecked(true);
		};
		initAsync();
	}, []);

	if (!hasAuthenticationChecked) {
		return <></>;
	}

	return (
		<PageWrapper>
			{({ claims }) => {
				return (
					<Switch>
						<Route path="/403" exact render={props => <Page403 {...props} />} />
						<Route exact path="/CivicPlusPlatform/SignIn" render={props => <SignIn {...props} />} />
						<Route exact path="/CivicPlusPlatform/SignInCallback" render={props => <SignInCallback {...props} />} />
						<Route exact path="/CivicPlusPlatform/SignOutCallback" render={props => <SignOut {...props} />} />
						<RestrictedRoute exact path="/" render={() => <Redirect from="/" to="/engage6/sites" />} />
						<RestrictedRoute exact path={`/engage6/sites`} component={SiteManagement} />
						<RestrictedRoute exact path={`/engage6/resources/certificates`} component={CertificatesListing} />
						<RestrictedRoute exact path={`/engage6/resources/databases`} component={DatabaseManager} />
						<RestrictedRoute
							exact
							path={`/hcms/production`}
							component={props => <HcmsAppManagement {...props} environmentType={"production"} />}
						/>
						<RestrictedRoute
							exact
							path={`/hcms/content-qa`}
							component={props => <HcmsAppManagement {...props} environmentType={"qa"} />}
						/>
						<RestrictedRoute
							exact
							path={`/hcms/content-review`}
							component={props => <HcmsAppManagement {...props} environmentType={"review"} />}
						/>
						<RestrictedRoute
							exact
							path={`/hcms/content-sandbox`}
							component={props => <HcmsAppManagement {...props} environmentType={"sandbox"} />}
						/>
						<RestrictedRoute
							exact
							path={`/hcms/content-staging`}
							component={props => <HcmsAppManagement {...props} environmentType={"staging"} />}
						/>
						<RestrictedRoute
							path={`/engage6/sites/:siteId`}
							component={({ match, ...props }) => <ManageSiteForm {...props} siteId={match.params.siteId} />}
						/>
						<RestrictedRoute
							path={`/engage6/operations/progress/:operation/:id`}
							component={({ match, ...props }) => (
								<OperationProgressManager {...props} id={match.params.id} operation={match.params.operation} />
							)}
						/>
						<RestrictedRoute exact path={`/engage6/diag`} component={Diagnostics} />
						{claims && claims.length > 0 && claims.includes(ClaimsConstants.HAS_ACCESS_TO_CLUSTERS) && (
							<>
								<RestrictedRoute exact path={`/engage6/resources/databases/querydbs`} component={QueryDbs} />
								<RestrictedRoute exact path={`/engage6/resources/clusters`} component={Clusters} />
								<RestrictedRoute
									path={`/engage6/resources/clusters/:clusterId`}
									component={({ match, ...props }) => (
										<ClusterCreateAndModify {...props} clusterId={match.params.clusterId} />
									)}
								/>
							</>
						)}
						<Route component={props => <div>{props.location.pathname} does not exist or you dont have access.</div>} />
					</Switch>
				);
			}}
		</PageWrapper>
	);
};

export default App;
