import React, { useState } from "react";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { Site } from "./Types/Site";
import { useHistory } from "react-router";
import { deleteSiteAsync } from "api/sitesApi";
import { useSnackbar } from "notistack";
import Loader from "components/loader/Loader";

export interface DeleteSiteModalProps {
	isOpen: boolean;
	site?: Site;
	onDeleteSuccess?: (deletedSiteId: string) => void;
	onDeleteFailure?: () => void;
	onDeleteCancel?: () => void;
	onDeleteClose?: () => void;
}

export const DeleteSiteModal: React.FC<DeleteSiteModalProps> = ({
	isOpen,
	site,
	onDeleteSuccess,
	onDeleteFailure,
	onDeleteCancel,
	onDeleteClose
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const onConfirmDelete = async () => {
		if (site) {
			setIsLoading(true);
			history.push(`/engage6/operations/progress/deleteSite/${site.id}`);

			try {
				await deleteSiteAsync(site.id);
				const message = `${site.name} deleted successfully.`;
				enqueueSnackbar(message, {
					variant: "success"
				});
				onDeleteSuccess?.(site.id);
			} catch (ex: any) {
				const message = `Deletion of ${site.name} failed.`;
				enqueueSnackbar(message, {
					variant: "error"
				});
				onDeleteFailure?.();
			}
		}
	};

	const onCancelDelete = () => {
		onDeleteCancel?.();
	};

	const onCloseDelete = () => {
		onDeleteClose?.();
	};

	return (
		<ConfirmationModal
			open={isOpen}
			title="Delete"
			content={
				isLoading ? <Loader /> : `Are you sure you want to delete the following site and all resources: ${site?.name}?`
			}
			onConfirm={onConfirmDelete}
			onCancel={onCancelDelete}
			onClose={onCloseDelete}
			fullHeight={false}
		/>
	);
};
