import StatusIndicator from "@civicplus/preamble-ui/lib/StatusIndicator";

export const serviceLevelOptions = [
	{
		value: "0",
		label: "None",
		description: "Should not be monitored; should not be considered for any actions.",
		statusShape: "triangle",
		statusColor: "#00008033"
	},
	{
		value: "1",
		label: "Internal In Production",
		description:
			"Site is being developed for a live client. Important for our teams, but no one needs to be called in the middle of the night.",
		statusShape: "circle",
		statusColor: "#F0900099"
	},
	{
		value: "2",
		label: "Live",
		description:
			"Live Client; Issues that occur are important; Monitor site closely; Engineers should be called in middle of night for issues.",
		statusShape: "circle",
		statusColor: "#008000"
	}
];

export const ServiceLevelStage = ({ stageId }) => {
	const option = serviceLevelOptions.find(o => o.value == stageId) || serviceLevelOptions[0];
	return (
		<StatusIndicator
			status={"CUSTOM"}
			customText={option.label}
			color={option.statusColor}
			shape={option.statusShape}
		/>
	);
};
