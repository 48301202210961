import React from "react";
import userManager from "../../api/userManager";
import Loader from "../loader/Loader";

export class SignInCallback extends React.Component {
	async componentDidMount() {
		await userManager.signInRedirectCallbackAsync();
	}

	render() {
		return <Loader>Loading...</Loader>;
	}
}
