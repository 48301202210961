import { ClusterModifications } from "components/Resources/Clusters/ClusterManagement";
import { Cluster } from "components/Resources/Clusters/Types/Cluster";
import { getApiUrl } from "utilities/environmentUtilities";
import { executeRequestAsyncFor } from "./apiUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/clusters`
	},
	get: {
		method: "GET",
		path: `${getApiUrl()}/api/clusters/{{id}}`
	},
	create: {
		method: "POST",
		path: `${getApiUrl()}/api/clusters`
	},
	update: {
		method: "PUT",
		path: `${getApiUrl()}/api/clusters/{{id}}`
	},
	delete: {
		method: "DELETE",
		path: `${getApiUrl()}/api/clusters/{{id}}`
	}
};

export const getClustersAsync = async (): Promise<Cluster[]> => {
	try {
		return (await executeRequestAsyncFor(endpoints.list)).data;
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		}
		throw error;
	}
};

export const createClusterAsync = async (clusterModifications: ClusterModifications): Promise<Cluster | null> => {
	try {
		return await executeRequestAsyncFor(endpoints.create, clusterModifications);
	} catch (error: any) {
		if (error.response && error.response.status === 408) {
			// Ignore request timeout
			console.error(error.response);
			return null;
		}
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		}
		throw error;
	}
};

export const deleteClusterAsync = async (clusterId: string): Promise<Cluster> => {
	try {
		return await executeRequestAsyncFor(endpoints.delete, { id: clusterId });
	} catch (error: any) {
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		}
		throw error;
	}
};
