import { executeRequestAsyncFor } from "./apiUtilities";
import { getApiUrl } from "../utilities/environmentUtilities";

const endpoints = {
	list: {
		method: "GET",
		path: `${getApiUrl()}/api/sites`
	},
	get: {
		method: "GET",
		path: `${getApiUrl()}/api/sites/{{id}}`
	},
	create: {
		method: "POST",
		path: `${getApiUrl()}/api/sites`
	},
	update: {
		method: "PUT",
		path: `${getApiUrl()}/api/sites/{{id}}`
	},
	delete: {
		method: "DELETE",
		path: `${getApiUrl()}/api/sites/{{id}}`
	},
	move: {
		method: "POST",
		path: `${getApiUrl()}/api/sites/{{siteId}}/move?destinationClusterId={{clusterId}}`
	}
};

/** Creates a site
 * @param {site,hcmsConfiguration, organizationId} createSiteRequest
 */
const createSiteAsync = createSiteRequest => {
	return executeRequestAsyncFor(endpoints.create, createSiteRequest);
};

const updateSiteAsync = (site, id) => {
	return executeRequestAsyncFor(endpoints.update, site, id);
};

const deleteSiteAsync = id => {
	return executeRequestAsyncFor(endpoints.delete, { id });
};

const fetchSiteAsync = id => {
	return executeRequestAsyncFor(endpoints.get, { id });
};

const fetchSitesAsync = () => {
	return executeRequestAsyncFor(endpoints.list);
};

const moveSiteAsync = options => {
	return executeRequestAsyncFor(endpoints.move, options);
};

export { fetchSiteAsync, fetchSitesAsync, createSiteAsync, updateSiteAsync, deleteSiteAsync, moveSiteAsync };
