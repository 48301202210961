import { useCallback } from "react";
import { OperationProgress } from "./OperationProgress";

export const OperationProgressManager = props => {
	const onCreateCompleted = useCallback(
		id => {
			props.history.push(`/engage6/sites/${id}`);
		},
		[props.history]
	);

	const onDeleteCompleted = useCallback(() => {
		props.history.push("/engage6/sites");
	}, [props.history]);

	const onClusterCreateCompleted = useCallback(() => {
		props.history.push(`/engage6/resources/clusters`);
	}, [props.history]);

	const onDiagnosticsCompleted = useCallback(() => {
		props.history.push("/engage6/diag");
	}, [props.history]);

	switch (props.operation) {
		case "createSite":
			return <OperationProgress hubName="setupSiteProgress" onComplete={onCreateCompleted} {...props} id={props.id} />;
		case "deleteSite":
			return (
				<OperationProgress {...props} hubName="deletionSiteProgress" onComplete={onDeleteCompleted} id={props.id} />
			);
		case "createCluster":
			return (
				<OperationProgress
					{...props}
					hubName="setupClusterProgress"
					onComplete={onClusterCreateCompleted}
					id={props.id}
				/>
			);
		case "diagnostics":
			return (
				<OperationProgress {...props} hubName="diagnosticsProgress" onComplete={onDiagnosticsCompleted} id={props.id} />
			);
		default:
			return `${props.operation} not supported.`;
	}
};
