import AdvancedTable from "@civicplus/preamble-ui/lib/AdvancedTable";
import Button from "@civicplus/preamble-ui/lib/Button";
import Menu from "@civicplus/preamble-ui/lib/Menu";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import { getClaims } from "api/claimsApi";
import { fetchSitesAsync } from "api/sitesApi";
import Loader from "components/loader/Loader";
import ClaimsConstants from "constants/claimsConstants";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getErrorMessageFromResponse } from "utilities/messageUtilities";
import { DeleteSiteModal, DeleteSiteModalProps } from "./DeleteSiteModal";
import { MoveModal, MoveModalProps } from "./MoveModal";
import { Site } from "./Types/Site";
import { ServiceLevelStage } from "./serviceLevel";

export const SiteManagement: React.FC = () => {
	const [hasInitialized, setHasInitialized] = useState<boolean>(false);
	const [sites, setSites] = useState<Site[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [moveModalProps, setMoveModalProps] = useState<MoveModalProps>({
		isOpen: false,
		site: undefined
	});
	const [deleteModalProps, setDeleteModalProps] = useState<DeleteSiteModalProps>({
		isOpen: false,
		site: undefined
	});
	const [hasAccessToClusters, setHasAccessToClusters] = useState(false);

	const fetchSites = useCallback(async () => {
		try {
			const sites = (await fetchSitesAsync()).data;
			setSites(sites);
			setIsLoading(false);
		} catch (ex: any) {
			if (ex && ex.response && ex.response.status === 403) {
				window.location.assign("/403");
				return;
			}
			console.error(ex);
			setIsLoading(false);
			enqueueSnackbar(getErrorMessageFromResponse(ex, "Failed to fetch sites."), {
				variant: "error"
			});
		}
	}, [enqueueSnackbar]);

	const fetchClaims = async () => {
		const claimsResult = await getClaims();
		if (claimsResult.includes(ClaimsConstants.HAS_ACCESS_TO_CLUSTERS)) setHasAccessToClusters(true);
	};

	useEffect(() => {
		if (!hasInitialized) {
			setHasInitialized(true);
			fetchSites();
			fetchClaims();
		}
	}, [enqueueSnackbar, hasInitialized, fetchSites]);

	const buildActionMenuFor = useCallback(
		(id: string) => {
			const site = sites.find(x => x.id === id);
			if (!site) {
				return [];
			}
			const actionMenu = [
				{
					display: "Modify",
					action: () => {
						history.push(`/engage6/sites/${id}`);
					}
				}
			];

			if (hasAccessToClusters)
				actionMenu.push(
					{
						display: "Move",
						action: () => {
							setMoveModalProps({
								isOpen: true,
								site: site
							});
						}
					},
					{
						display: "Delete",
						action: async () => {
							setDeleteModalProps({
								isOpen: true,
								site: site
							});
						}
					}
				);

			return actionMenu;
		},
		[sites, hasAccessToClusters, history]
	);

	const closeMoveModal = useCallback(() => {
		fetchSites();
		setMoveModalProps({
			isOpen: false,
			site: undefined
		});
	}, [fetchSites]);

	const onDeleteSuccess = useCallback(
		(deletedId: string) => {
			const newSitesCollection = sites.filter(site => site.id !== deletedId);
			setSites(newSitesCollection);
			setDeleteModalProps(() => ({
				isOpen: false,
				site: undefined
			}));
		},
		[sites]
	);

	const closeDeleteModal = useCallback(() => {
		setDeleteModalProps(() => ({
			isOpen: false,
			site: undefined
		}));
	}, []);

	const getSiteDataForAdvancedTable = useCallback(
		(site: Site) => {
			const id = site.id;
			const name = site.name;
			const databaseServer = site.databaseServer;
			const databaseName = site.databaseName;
			const domainName = site.domains && site.domains.length && site.domains[0].domainName;
			const menuId = id + "-action-menu";
			const cluster = site.cluster && site.cluster.name;
			const serviceLevelStage = site.serviceLevelStage;
			return [
				{ id, domainName, name },
				databaseServer,
				databaseName,
				cluster,
				{ id, serviceLevelStage },
				<Menu key={site.id} id={menuId} type="action" items={buildActionMenuFor(id)} />
			];
		},
		[buildActionMenuFor]
	);

	const columns = useMemo(
		() => [
			{
				name: "Site Name",
				displayName: "Site Name",
				options: {
					filter: true,
					filterOptions: {
						names: sites.map(site => site.name),
						renderValue: sites.map(site => site.name),
						logic: (site: any, filters: any): any => {
							if (filters.length) return !site.name.includes(filters[0]);
						}
					},
					sort: true,
					customBodyRender: (site: any) => (
						<span key={site.id}>
							{site.domainName && (
								<a target="_blank" href={`https://${site.domainName}`} rel="noopener noreferrer">
									<OpenInBrowserIcon
										style={{ color: "#000", marginLeft: "3px", verticalAlign: "middle", fontSize: "20px" }}
									/>
								</a>
							)}
							{site.name}
						</span>
					)
				}
			},
			{
				name: "Database Server",
				displayName: "Database Server",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Database Name",
				displayName: "Database Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Cluster",
				displayName: "Cluster",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Stage",
				displayName: "Stage",
				options: {
					filter: false,
					sort: true,
					customBodyRender: (site: any) => <ServiceLevelStage key={site.id} stageId={site.serviceLevelStage} />
				}
			},
			{
				name: "",
				displayName: "",
				options: {
					filter: false,
					sort: false,
					viewColumns: false
				}
			}
		],
		[sites]
	);

	const options = {
		pagination: false,
		responsive: "vertical",
		selectableRows: "none",
		customSort: (data: any[], colIndex: number, order: string): any[] => {
			return data.sort((a, b) => {
				switch (colIndex) {
					//Site Name + HCMS Link Icon column must be sorted by site name
					case 0:
						a = a.data[colIndex].name.toLowerCase();
						b = b.data[colIndex].name.toLowerCase();
						break;

					//Stage column must sorted by service level stage value
					case 4:
						a = a.data[colIndex].serviceLevelStage ?? 0;
						b = b.data[colIndex].serviceLevelStage ?? 0;
						break;

					//Regular text columns should be sorted normally
					default:
						a = a.data[colIndex].toLowerCase();
						b = b.data[colIndex].toLowerCase();
				}

				if (a === b) return 0;

				return (a < b ? -1 : 1) * (order === "desc" ? 1 : -1);
			});
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div>
				<Link to="/engage6/sites/new">
					<Button id="site-add">ADD SITE</Button>
				</Link>
			</div>
			<AdvancedTable
				title="Platform Sites and Services"
				columns={columns}
				data={sites.map(site => getSiteDataForAdvancedTable(site))}
				options={options}
			/>
			<MoveModal
				isOpen={moveModalProps.isOpen}
				site={moveModalProps.site}
				onMoveSuccess={closeMoveModal}
				onMoveFailure={closeMoveModal}
				onMoveCancel={closeMoveModal}
				onMoveClose={closeMoveModal}
			/>
			<DeleteSiteModal
				isOpen={deleteModalProps.isOpen}
				site={deleteModalProps.site}
				onDeleteSuccess={onDeleteSuccess}
				onDeleteFailure={closeDeleteModal}
				onDeleteCancel={closeDeleteModal}
				onDeleteClose={closeDeleteModal}
			/>
		</>
	);
};
